import React, { useEffect, useRef } from 'react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion, useInView } from 'framer-motion';
import { titleVariants } from '../animations';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { portfolioData } from '../portfoiloData';

const Slider = () => {
  const sectionRef = useRef(null);
  const swiperRef = useRef(null); 
  const isInView = useInView(sectionRef, { once: false, amount: 0.3 });

  useEffect(() => {
    if (swiperRef.current) {
      if (isInView) {
        swiperRef.current.autoplay.start();
      } else {
        swiperRef.current.autoplay.stop();
      }
    }
  }, [isInView]);

  console.log('isInView', isInView)
  return (
    <section id='portfolio' className='slider-section' ref={sectionRef}>
      <div className='content'>
        <motion.div
          className='title'
          variants={titleVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}>
          <h3>지금도 대한민국의 산업 리더들은</h3>
          <h3 className='pc'>
            디엔소프트와 함께 에듀테크를 혁신하고 있습니다.
          </h3>
          <h3 className='mo'>디엔소프트와 함께</h3>
          <h3 className='mo'>에듀테크를 혁신하고 있습니다.</h3>
        </motion.div>

        <motion.div
          variants={titleVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          className='swiper-wrap'
          >
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            slidesPerView={2}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            spaceBetween={10}
            centeredSlides={true}
            navigation={false}
            loop={true}
            grabCursor={true}
            pagination={{ clickable: false }}
            autoplay={{ delay: 6000, disableOnInteraction: false }} 
            onSwiper={(swiper) => (swiperRef.current = swiper)} 
          >
            {portfolioData.map((item, index) => (
              <SwiperSlide key={index}>
                <a href={`/portfolio/${item.id}`} className='portfolio-card'>
                  <div className='image'>
                    {item.images.thumbnail ? (
                      <img
                        className={`thumbnail ${item.class ? item.class : ''}`}
                        src={require(
                          `../assets/images/${item.images.thumbnail}.jpg`
                        )}
                        alt={item.title}
                      />
                    ) : item.images.main ? (
                      <img
                      className={item.class ? item.class : ''}
                        src={require(
                          `../assets/images/${item.images.main}.jpg`
                        )}
                        alt={item.title}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='text'>
                    <h4>
                      {item.title}
                      <br />
                      {item.subtitle}
                    </h4>
                    <span className='more-btn'>더보기 {'>'}</span>
                  </div>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
      </div>
    </section>
  );
};
export default Slider;
