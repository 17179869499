import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import background from '../assets/images/main_bg_mobile.png';

const Home = () => {
  const mainSectionRef = useRef(null);
  const { scrollY } = useScroll();
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const backgroundY = useTransform(
    scrollY,
    [800, 0],
    isMobile ? [-1000, 0] : [-1500, 0]
  );

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    const threshold = isMobile ? 300 : 650;

    if (scrollPosition > threshold) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleScroll, handleResize]);

  return (
    <section
      ref={mainSectionRef}
      className={`main-section ${!isVisible ? 'hidden' : ''}`}>
      <div className='content'>
        <div className='title'>
          <motion.h1
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2, delay: 0.2 }}>
            에듀테크
          </motion.h1>
          <motion.h1
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.3, delay: 0.4 }}>
            스튜디오
          </motion.h1>
          <motion.h1
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.4, delay: 0.6 }}>
            디엔소프트
          </motion.h1>
        </div>

        <motion.div
          className='text'
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, delay: 0.8 }}>
          <p>
            디엔소프트는 AI와 AR/VR 기술로
            <br />
            몰입형 교육 콘텐츠를 제작하는 에듀테크 스튜디오입니다.
            <br />
            학습자들에게 직관적이고 체험적인 교육을 제공하며,
            <br />
            모든 학습자의 꿈과 가능성을 실현할 수 있도록 돕습니다.
          </p>
        </motion.div>

        <ul className='icon'>
          <motion.li
            drag
            dragSnapToOrigin
            className='icon_chat'
            animate={{ y: [0, -13, 0], x: [0, 10, 0], rotate: [0, -5, 0] }}
            transition={{ duration: 4, repeat: Infinity }}></motion.li>
          <motion.li
            drag
            dragSnapToOrigin
            dragElastic={1}
            className='icon_plus'
            animate={{ y: [0, -10, 0], rotate: [0, -10, 0] }}
            transition={{ duration: 4, repeat: Infinity }}></motion.li>
          <motion.li
            drag
            dragSnapToOrigin
            dragElastic={1}
            className='icon_lightning'
            animate={{ rotate: [0, 18, 0] }}
            transition={{ duration: 5, repeat: Infinity }}></motion.li>
          <motion.li
            drag
            dragSnapToOrigin
            dragElastic={1}
            className='icon_cursor'
            animate={{ y: [0, -15, 0], rotate: [0, 8, 0] }}
            transition={{ duration: 4, repeat: Infinity }}></motion.li>
        </ul>
      </div>

      <motion.div
        className='bg'
        style={{
          y: backgroundY,
        }}>
        <img
          src={background}
          alt='background'
          transition={{ duration: 6, delay: 1, ease: 'easeOut' }}
        />
      </motion.div>
    </section>
  );
};
export default Home;
