export const HeaderVariants = {
  hideNavItems: {
    opened: {
      opacity: 0,
      y: '-100%',
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
    closed: {
      opacity: 1,
      y: '0%',
      transition: { delay: 1.1, duration: 0.5, ease: 'easeInOut' },
    },
  },
  mobileMenu: {
    opened: {
      y: '0%',
      transition: { delay: 0.15, duration: 1.1, ease: [0.74, 0, 0.19, 1.02] },
    },
    closed: {
      y: '-100%',
      transition: { delay: 0.35, duration: 0.63, ease: [0.74, 0, 0.19, 1.02] },
    },
  },
  fadeIn: {
    opened: { opacity: 1, transition: { delay: 1.2 } },
    closed: { opacity: 0 },
  },
  ul: {
    opened: { transition: { delayChildren: 1, staggerChildren: 0.18 } },
    closed: { transition: { staggerChildren: 0.06, staggerDirection: -1 } },
  },
  li: {
    opened: {
      opacity: 1,
      y: '0%',
      transition: { duration: 0.65, ease: 'easeOut' },
    },
    closed: {
      opacity: 0,
      y: '100%',
      transition: { duration: 0.25, ease: 'easeInOut' },
    },
  },
};

export const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.2,
      delay: 0.4,
      type: 'spring',
      stiffness: 30,
    },
  },
};

export const titleVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.2,
      delay: 0.3,
      type: 'spring',
      stiffness: 50,
    },
  },
};

export const titleLeftVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1.2,
      delay: 0.2,
      // type: 'spring',
      // stiffness: 50,
    },
  },
};

export const textLeftVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1.2,
      delay: 0.4,
      // type: 'spring',
      // stiffness: 100,
    },
  },
};

export const textVariants = {
  hidden: { opacity: 0 },
  visible: (delay = 0) => ({
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
      delay: 1.2 + delay * 0.2,
    },
  }),
};

export const footerVariants = {
  footer: {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: 'easeOut' },
    },
  },
  icons: {
    hidden: { transition: { staggerChildren: 0.05 } },
    visible: { transition: { delayChildren: 0.3, staggerChildren: 0.2 } },
  },
  icon: {
    hidden: { opacity: 0, X: -30 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.6, ease: 'easeOut' },
    },
  },
  bottom: {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: 'easeOut', delay: 0.5 },
    },
  },
  border: {
    hidden: {
      scaleX: 0,
      transformOrigin: 'left',
    },
    visible: {
      scaleX: 1,
      transformOrigin: 'left',
      transition: {
        duration: 1.5,
        ease: 'easeOut',
      },
    },
  },
};

export const PortfolioVariants = {

};