import React from 'react';
import { useNavigate } from 'react-router-dom';

function Privacy() {
  const navigate = useNavigate();

  return (
    <div className='privacy-container'>
      <button className='prev-btn' onClick={() => navigate(-1)}></button>
      <button className='close-btn' onClick={() => navigate(-1)}></button>
      
      <div className='inner'>
        <h3 className='title'>개인정보처리방침</h3>

        <div className='privacy-content'>
          <h4>
            [디엔소프트]는 고객의 문의사항 처리를 위해 필요한 최소한의
            개인정보를 수집·이용하며, 이를 안전하게 보호하기 위해 최선을 다하고
            있습니다.
            <br />
            회사는 「개인정보 보호법」 및 관련 법령에 따라 개인정보를 안전하게
            관리하며, 다음과 같은 방침을 통해 고객의 개인정보를 보호합니다.
          </h4>

          <div className='privacy-sections'>
            <div className='privacy-section'>
              <h6>1. 수집하는 개인정보 항목</h6>

              <p>필수항목: 이름, 이메일 주소, 문의 내용</p>
              <p>
                선택항목: 전화번호 (필요 시), 기타 고객이 제공하는 추가 정보
              </p>
              <p>자동 수집 정보: IP 주소, 브라우저 정보 (채널톡 이용 시)</p>
            </div>

            <div className='privacy-section'>
              <h6>2. 개인정보의 수집 및 이용 목적</h6>

              <p>고객 문의에 대한 응대 및 처리</p>
              <p>문제 해결 및 추가 정보 제공</p>
              <p>서비스 품질 향상을 위한 통계 분석 (개인 식별 불가 형태)</p>
            </div>

            <div className='privacy-section'>
              <h6>3. 개인정보의 보유 및 이용 기간</h6>

              <p>문의 처리 완료 후 1년 이내 삭제</p>
              <p>단, 관련 법령에 따라 보존이 필요한 경우 해당 기간 동안 보관</p>
              <ul>
                <li>
                  「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 보관
                </li>
                <li>계약 또는 청약철회 등에 관한 기록: 5년</li>
                <li>소비자 불만 또는 분쟁처리에 관한 기록: 3년</li>
              </ul>
            </div>

            <div className='privacy-section'>
              <h6>4. 개인정보의 제3자 제공 여부</h6>

              <p>
                회사는 고객의 동의 없이 개인정보를 제3자에게 제공하지 않습니다.
              </p>
              <p>
                다만, 법령에 따라 요구되는 경우, 고객의 동의를 받은 경우에
                한하여 제공됩니다.
              </p>
            </div>

            <div className='privacy-section'>
              <h6>5. 개인정보 처리 위탁</h6>

              <p>
                회사는 현재 개인정보 처리와 관련하여 외부에 위탁하는 업무가
                없습니다.
              </p>
              <p>
                향후 위탁처리가 필요한 경우, 관련 법령에 따라 고객의 개인정보를
                안전하게 처리하기 위해 위탁업체와 필요한 계약을 체결하고,
                <br />
                해당 내용을 개인정보 처리방침에 즉시 반영하여 공개할 예정입니다.
              </p>
            </div>

            <div className='privacy-section'>
              <h6>6. 정보주체의 권리 및 행사 방법</h6>

              <p>
                고객은 언제든지 자신의 개인정보 열람, 수정, 삭제를 요청할 수
                있습니다.
              </p>
              <p>요청은 아래 개인정보 보호책임자 연락처를 통해 가능합니다.</p>
            </div>

            <div className='privacy-section'>
              <h6>7. 개인정보 처리방침의 변경</h6>

              <p>
                본 방침은 시행일로부터 적용되며, 변경 사항이 있을 경우 홈페이지
                공지사항(또는 개별 통지)을 통해 안내드릴 예정입니다.
              </p>
            </div>

            <div className='privacy-section'>
              <h6>8. 개인정보 보호책임자 연락처</h6>

              <p>이름: 김성수</p>
              <p>직책: 개인정보 보호책임자</p>
              <p>이메일: seongsu@dnsoft.co.kr</p>
              <p>전화번호: 010-7593-2487</p>
            </div>
          </div>
          <span className='privacy-date'>시행일: 2024년 12월 01일</span>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
