import React, { useEffect, useRef } from 'react';
import { ReactLenis, useLenis } from 'lenis/react';
import 'lenis/dist/lenis.css';
import Home from '../views/Home';
import About from '../views/About';
import Service from '../views/Service';
import Project from '../views/Project';
import Slider from '../views/Slider';
import Contact from '../views/Contact';
import Footer from '../component/Footer';
import Header from '../component/Header';
import ChannelService from '../ChannelService';
import ScrollToTop from '../component/ScrollToTop';
import { useLocation } from 'react-router-dom';

function Main() {
  const { state } = useLocation();
  const lenis = useLenis();

  ChannelService.loadScript();

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CHANNEL_KEY,
  });

  const defaultLenisOptions = {
    lerp: 0.08,
    duration: 1.5,
    smoothWheel: true,
    wheelMultiplier: 0.6,
    smooth: true,
    orientation: 'vertical',
    gestureOrientation: 'vertical',
    touchMultiplier: 1.5,
    infinite: false,
    normalizeWheel: false,
    easing: t => Math.min(1, 1.001 - Math.pow(2, -6 * t)),
  };

  useEffect(() => {
    if (state?.scrollToPortfolio && lenis) {
      setTimeout(() => {
        lenis.scrollTo('#portfolio');
      }, 100);
    }
  }, [state, lenis]);

  return (
    <>
      <Header />
      <ReactLenis root options={defaultLenisOptions}>
        <Home />
        <About />
        <Service />
        <Project />
        <Slider />
        <Contact />
      </ReactLenis>
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default Main;
