import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { titleLeftVariants } from '../animations';
import { useLenis } from 'lenis/react';

const Contact = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    title: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const lenis = useLenis();

  useEffect(() => {
    if (isModalOpen) {
      lenis?.stop();
    } else {
      lenis?.start();
    }
  }, [isModalOpen, lenis]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = '이름을 입력해주세요.';
    if (!formData.email.trim()) newErrors.email = '이메일을 입력해주세요.';

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = '올바른 이메일 형식이 아닙니다.';
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    const submitButton = e.target.querySelector('input[type="submit"]');
    submitButton.disabled = true;
  
    try {
      const response = await fetch(e.target.action, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });
  
      if (!response.ok) {
        throw new Error('서버 응답 오류');
      }
  
      setIsModalOpen(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        title: '',
        message: '',
      });
      setErrors({});
  
    } catch (error) {
      console.error('Error:', error);
      alert('전송 중 오류가 발생했습니다. 다시 시도해주세요.');
    } finally {
      submitButton.disabled = false;
    }
  };

  return (
    <section id='contact' className='contact-section'>
      <div className='content inner'>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className='contact'>
          <div className='form-header'>
            <motion.h4
              variants={titleLeftVariants}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true, amount: 0.4 }}>
              디엔소프트와 함께
              <br />
              에듀테크의 미래를
              <br />
              열어보세요.
            </motion.h4>

            <motion.div
              variants={titleLeftVariants}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true, amount: 0.6 }}>
              <p className='pc'>
                디엔소프트는 혁신적인 기술과 창의적인 솔루션으로 교육의 미래를
                열어갑니다.
                <br />
                우리의 전문성은 단순한 서비스를 넘어 파트너의 비전을 실현하는 데
                집중합니다.
                <br />
                교육과 실감형 콘텐츠의 새로운 가능성을 함께 발견하고 싶다면
                언제든지 저희에게 문의하세요!
              </p>
              <p className='mo'>
                디엔소프트는 혁신 기술과 창의적 솔루션으로 교육의 미래를
                열어가며, 파트너의 비전 실현을 지원합니다. 교육과 실감형
                콘텐츠의 가능성을 함께 탐구하고 싶다면 언제든 문의하세요!
              </p>
            </motion.div>
          </div>

          <motion.div
            className='form-wrap'
            variants={titleLeftVariants}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.4 }}>
            <form
              onSubmit={handleSubmit}
              action='https://script.google.com/macros/s/AKfycbyEN-4juBjKrdrNEBFNyqCjUXQJ7jPcwZGsKbmokB_Kj4wEGdyBJsTsTms1hzVeSjj_WA/exec'
              method='POST'
              className='gform'>
              <div className='flex'>
                <div className='item'>
                  <div>
                    <label>
                      이름 <span>*</span>
                    </label>
                    <input
                      type='text'
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      placeholder='홍길동'
                      className={errors.name && !formData.name ? 'error' : ''}
                    />
                    {errors.name && !formData.name && (
                      <span className='error-message'>{errors.name}</span>
                    )}
                  </div>
                  <div>
                    <label>
                      이메일 <span>*</span>
                    </label>
                    <input
                      type='email'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      placeholder='example@dnsoft.co.kr'
                      className={errors.email && !formData.email ? 'error' : ''}
                    />
                    {errors.email && !formData.email && (
                      <span className='error-message'>{errors.email}</span>
                    )}
                  </div>
                  <div>
                    <label>전화번호</label>
                    <input
                      type='tel'
                      name='phone'
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder='010-1234-5678'
                    />
                  </div>
                </div>
                <div className='item'>
                  <div>
                    <label>제목</label>
                    <input
                      type='text'
                      name='title'
                      value={formData.title}
                      onChange={handleChange}
                      placeholder='제목을 입력해주세요.'
                    />
                  </div>
                  <div>
                    <label>내용</label>
                    <textarea
                      type='text'
                      name='message'
                      value={formData.message}
                      onChange={handleChange}
                      placeholder='내용을 입력해주세요'
                    />
                  </div>
                </div>
              </div>
              <div className='btn-wrap'>
                <input type='submit' value='보내기' />
              </div>
            </form>
          </motion.div>
        </motion.div>

        <div className={`contact-modal ${isModalOpen ? 'open' : ''}`}>
          <div className='modal-content'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='56'
              height='57'
              viewBox='0 0 56 57'
              fill='none'>
              <circle cx='28' cy='28.5' r='28' fill='#2E63CA' />
              <path
                d='M14 28.0723L24.5263 38.7667L42 18.2334'
                stroke='white'
                strokeWidth='3.73333'
                strokeLinejoin='round'
              />
            </svg>
            <div>
              <h6>전송 완료</h6>
              <p>빠른 시일 내에 답장드리겠습니다.</p>
            </div>
            <button onClick={() => setIsModalOpen(false)} className='close-btn'>확인</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
