import React, { useCallback, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoutes } from './routes';
import { AnimatePresence } from 'framer-motion';
import SplashScreen from './views/SplashScreen';
import './assets/css/style.scss';

function App() {
  const [isSplashVisible, setSplashVisible] = useState(() => {
    // 세션 스토리지 확인
    const skipSplash = sessionStorage.getItem('skipSplash');
    if (skipSplash === 'true') {
      return false;
    }

    // 네비게이션 타입 확인
    const navigationEntries = performance.getEntriesByType('navigation');
    const navigationType = navigationEntries.length > 0 ? navigationEntries[0].type : 'navigate';

    return navigationType === 'reload' || navigationType === 'navigate';
  });

  const skipSplash = useCallback(() => {
    setSplashVisible(false);
  }, []);

  useEffect(() => {
    if (isSplashVisible) {
      const timer = setTimeout(() => {
        skipSplash();
      }, 3500);

      return () => clearTimeout(timer);
    }
  }, [isSplashVisible, skipSplash]);

  const router = createBrowserRouter(
    createRoutes(isSplashVisible, SplashScreen),
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
      },
    }
  );

  return (
    <AnimatePresence mode='wait'>
      <RouterProvider router={router} />
    </AnimatePresence>
  );
}

export default App;
