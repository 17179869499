import React from 'react';
import { motion } from 'framer-motion';
import { titleVariants, containerVariants } from '../animations';
import logo1 from '../assets/images/company_logo1.png';
import logo2 from '../assets/images/company_logo2.png';
import logo3 from '../assets/images/company_logo3.png';
import logo4 from '../assets/images/company_logo4.png';
import logo5 from '../assets/images/company_logo5.png';
import logo6 from '../assets/images/company_logo6.png';
import logo7 from '../assets/images/company_logo7.png';
import logo8 from '../assets/images/company_logo8.png';
import logo9 from '../assets/images/company_logo9.png';
import logo10 from '../assets/images/company_logo10.png';

const TitleSection = () => (
  <motion.div
    className='title'
    variants={titleVariants}
    initial='hidden'
    whileInView='visible'
    viewport={{ once: true, amount: 0.5 }}>
    <h3>최신 생성형 AI로</h3>
    <h3>맞춤형 콘텐츠 제작 서비스 제공</h3>
  </motion.div>
);

const MarqueeSection = ({ logos }) => (
  <motion.div
    className='marquee-advanced'
    variants={containerVariants}
    initial='hidden'
    whileInView='visible'
    viewport={{ once: true, amount: 0.6 }}>
    <div className='marquee-track-advanced'>
      {[...Array(2)].map((_, idx) => (
        <div key={idx} className='marquee-content-advanced'>
          {logos.map((logo, index) => (
            <div key={index} className='marquee-image'>
              <img src={logo.img} alt={logo.name} />
            </div>
          ))}
        </div>
      ))}
    </div>
  </motion.div>
);

const HoverCard = ({
  thumb,
  title,
  titleMobile,
  description,
  type,
  clients,
}) => (
  <motion.div
    className='hover-card'
    whileHover={{
      transition: {
        duration: 1,
        ease: 'easeOut',
      },
    }}>
    <div className='thumbnail'>
      <div className={`thumb-bg ${thumb}`}></div>
      <span>{type}</span>
      <p className='text pc'>{title}</p>
      <p className='text mo'>{titleMobile}</p>
    </div>
    <div className='hover-item'>
      <div className={`thumb-bg ${thumb}`}></div>
      <div className='item'>
        <div className='flex'>
          <div className='left'>
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
          <div className='right'>
            <h4>{type}</h4>
          </div>
        </div>
        <p className='client'>주요고객사: {clients}</p>
      </div>
    </div>
  </motion.div>
);

const Service = () => {
  const logos = [
    {
      name: '천재교육',
      img: logo1,
    },
    {
      name: '크레버스',
      img: logo2,
    },
    {
      name: '에코프로비엠',
      img: logo3,
    },
    {
      name: 'KB국민은행',
      img: logo4,
    },
    {
      name: 'Konkuk',
      img: logo5,
    },
    {
      name: 'KT',
      img: logo6,
    },
    {
      name: 'LG유플러스',
      img: logo7,
    },
    {
      name: 'nipa 정보통신산업진흥원',
      img: logo8,
    },
    {
      name: 'SK 텔레콤',
      img: logo9,
    },
    {
      name: '윤선생',
      img: logo10,
    },
  ];

  return (
    <section id='services' className='service-section'>
      <div className='content'>
        <TitleSection />
        <MarqueeSection logos={logos} />

        <motion.div
          className='hover-card-section'
          variants={titleVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}>
          <HoverCard
            thumb='thumb1'
            type='AI'
            title={
              <>
                AI 디지털교과서 및<br />
                AI 교육 콘텐츠 제작
              </>
            }
            titleMobile={
              <>
                AI 디지털교과서 및<br />
                AI 교육
                <br />
                콘텐츠 제작
              </>
            }
            description='최신 AI 기술을 콘텐츠와 융합하여 지능형 교육 콘텐츠 제작 서비스를 제공합니다. 대규모 언어 모델(LLM)의 추론 기능을 통해 학습 콘텐츠에 지능적이고 적응적인 요소를 부여, 학습자를 이해하고 상호작용하는 콘텐츠로 재탄생시킵니다'
            clients='천재교육, 크레버스, 윤선생 영어 등'
          />
          <HoverCard
            thumb='thumb2'
            type='MR'
            title={
              <>
                혼합현실 기술로
                <br />
                현장 실감형 콘텐츠 구현
              </>
            }
            titleMobile={
              <>
                혼합 현실 기술로
                <br />
                현장 실감형
                <br />
                콘텐츠 구현
              </>
            }
            description='우리는 AR글래스 기반의 혼합현실 프로젝트를 국내에서 최대규모 수준으로 경험한 개발사입니다. 2017년부터 교육, 제조, 통신, 서비스분야 국내 대표기업들에 홀로렌즈(Hololens)를 비롯한 다양한 혼합현실(Mixed Reality)글래스에 최적화된 몰입형 교육 콘텐츠 제작 서비스를 제공하고 있습니다.'
            clients='SK텔레콤, KB국민은행, 건국대학교, 정보통신산업진흥원 등'
          />
          <HoverCard
            thumb='thumb3'
            type='VR'
            title={
              <>
                메타버스와 가상현실 기술로
                <br />
                몰입형 학습 환경 조성
              </>
            }
            titleMobile={
              <>
                메타버스와
                <br />
                가상현실 기술로
                <br />
                몰입형 학습 환경
              </>
            }
            description='가상현실 디바이스를 위한 완성도 높은 VR 교육/게임 콘텐츠 및 메타버스 플랫폼을 제작합니다. 인공지능 캐릭터 액팅 및 머신러닝 시스템을 기반으로 고객 요구사항에 유연하게 대응할 수 있도록 Oculus, VIVE, PC, Mobile 크로스 플랫폼에서 활용 가능한 제작 체계를 갖추었습니다.'
            clients='LG유플러스, KT, 에코프로비엠 등'
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Service;
