export const portfolioData = [
  {
    id: 1,
    title: '천재교육',
    subtitle: 'AI 디지털교과서',
    year: '2024',
    tags: ['#AI', '#디지털교과서', '#에듀테크'],
    description: `이 프로젝트는 천재교육과 협력하여 개발된 AI 기반 디지털교과서 플랫폼입니다. 웹(Web) 환경에서 구동되며 Vue.js로 개발되었습니다. 
    이 플랫폼은 초등학교 3~4학년 학생들을 대상으로 하며, AI를 활용한 맞춤형 학습 지원을 제공합니다. 특히 AI 음성인식 및 분석 기술을 적용하여 학생들의 학습 능력을 향상시키는 데 초점을 맞추었습니다. 
    교과서의 저자는 총 3명으로 함순애, 김태은, 이동환 교과서의 콘텐츠를 개발했으며 2025년 3월부터 초등학교 3~4학년을 대상으로 정식 도입될 예정입니다.`,
    platform: ['Web'],
    features: [
      {
        title: 'AI 기반 학습 지원',
        description: "'Let's Talk', 'Let's Write' 기능을 통한 회화 및 쓰기 첨삭 지원, Role-play 기능을 활용한 실전 회화 연습",
      },
      {
        title: '맞춤형 학습 시스템',
        description: 'AI 진단평가를 통해 학생 개개인의 수준을 분석 후 개인 맞춤형 학습 콘텐츠 제공',
      },
      {
        title: '교사 지원 기능',
        description: '실시간 학급 모니터링 시스템, 학생별 성취도 데이터 분석 및 리포트 제공',
      },
    ],
    results: [
      {
        title: '교육 혁신',
        description: '맞춤형 학습을 통해 학습 효율성 극대화, AI 기술을 활용한 새로운 교육 방식 도입',
      },
      {
        title: '학습 효과',
        description: 'AI 기반 평가 시스템을 통한 학습 개선 및 효율성 증대, 학생들의 적극적인 참여 유도 및 높은 만족도 확보',
      },
      {
        title: '지속적 협력',
        description: '천재교육과의 장기적 파트너십을 통해 AI 교육 시장 확장, 향후 초등학교 전 학년으로 확대 도입 계획',
      },
    ],
    images: {
      thumbnail: 'chunjae_aidt_thumbnail',
      main: 'chunjae_aidt_main',
      sub: ['chunjae_aidt_sub1', 'chunjae_aidt_sub2'],
    },
  },

  {
    id: 2,
    title: '윤선생영어',
    subtitle: 'Y-Planet',
    year: '2024',
    class: 'yplanet',
    tags: ['#에듀테크', '#게이미피케이션', '#AI', '#챗봇', '#메타버스'],
    description: `윤선생영어의 'Y-플래닛'은 초등학생을 위한 메타버스 기반 영어 학습 애플리케이션입니다. 이 앱은 윤선생 스마트학습 과정에서 학습한 단어와 문장, 핵심 표현을 게임 형식의 액티비티로 복습할 수 있도록 설계되었습니다. 사용자는 다양한 미션과 퀘스트를 완료하여 보석, 골드, 베플리포인트 등의 재화를 획득하고, 이를 통해 캐릭터를 꾸미거나 행성 내 건물을 지을 수 있습니다. 또한, AI 챗봇 '피비'와의 대화를 통해 영어 말하기 훈련도 가능합니다. 현재 Y-플래닛은 윤선생 방문학습 회원을 대상으로, 구글 플레이스토어에서 다운로드할 수 있습니다.`,
    platform: ['Android'],
    features: [
      {
        title: '메타버스 학습 환경',
        description: '학생들이 몰입감 있게 학습 할 수 있는 가상 공간',
      },
      {
        title: '게임형 복습',
        description: '학습한 단어 및 문장 복습을 위한 인터랙티브 게임',
      },
      {
        title: '커뮤니케이션 AI',
        description: "영어 말하기 훈련을 돕는 AI 챗봇 '피비'",
      },
    ],
    results: [
      {
        title: '반응 및 효과',
        description:
          '앱 출시 후 사용자들의 긍정적인 효과로 매년 추가 계약 달성',
      },
      {
        title: '사용자 참여도',
        description: '학습 참여 증가, 학습 효율성 향상',
      },
    ],
    images: {
      main: 'yplanet_main',
      sub: ['yplanet_sub1', 'yplanet_sub2'],
    },
  },
  {
    id: 3,
    title: '서울대학교',
    subtitle: '드림어스',
    year: '2024',
    tags: ['#VR', '#AI', '#챗봇', '#메타버스', '#심리치료'],
    description: `DREAMUS(드림어스)는 꿈의 본질을 탐구하는 인터랙티브 AI VR 예술 작품입니다.
      이 프로젝트는 심리치료 기반의 '드림워크(Dreamwork)'를 바탕으로, 꿈을 통해 개인의 무의식을 탐구하고 자아에 대한 깊은 인식을 도모할 수 있도록 설계되었습니다. DREAMUS는 메타버스 플랫폼을 활용해 사용자가 꿈에서 영감을 받은 예술 작품을 창작하고 이를 공유할 수 있게 하며, 창의적 자유와 예술적 소통이 활성화되는 공간을 제공합니다.
      이 몰입형 예술 공간은 예술, 기술, 심리학을 결합하여, 창의성과 자아 인식, 심리적 자유가 공존하는 새로운 경험을 선사합니다.
      궁극적으로, DREAMUS는 참가자들이 예술적 창작을 통해 자신을 발견하고 꿈과 자아를 깊이 이해에 도달할 수 있는 특별한 기회를 제공합니다. 또한 DREAMUS는 물리적 경계를 넘어서며 참가자들의 개별적 상상력과 자아 탐구를 확장하는 독창적인 여정을 제시합니다.`,
    platform: ['Android', 'Web', 'Meta(Oculus)'],
    features: [
      {
        title: '인터랙티브 VR 예술 창작',
        description:
          '꿈에서 영감을 받아 예술을 창작하고 공유하는 메타버스 기반 VR 환경',
      },
      {
        title: '심리 치료 기반의 꿈 분석',
        description:
          '드림워크(Dreamwork) 기법으로 꿈을 탐구하며 자아에 대한 인식을 높이는 기능',
      },
      {
        title: '몰입형 예술 공간',
        description:
          '예술, 기술, 심리학이 결합된 창의적 소통과 자유를 위한 공간.',
      },
      {
        title: '자아 탐구 및 창의성 발현',
        description:
          '꿈과 자아를 탐구하고, 예술적 창작을 통해 자아를 깊이 이해할 수 있는 기회',
      },
    ],
    results: [
      {
        title: '참가자 경험 향상',
        description: '예술적 창작을 통해 자신을 발견하고 꿈과 자아를 깊이 이해',
      },
      {
        title: '창의성 및 상상력 확장',
        description: '상상력과 창의성이 극대화되어 꿈의 독창적인 작품들이 창출',
      },
      {
        title: '심리적 자유와 소통 활성화',
        description:
          '예술을 통한 심리적 자유를 경험하고, 창작물을 공유하면서 활발한 소통을 이어감',
      },
      {
        title: '꿈과 자아 탐구',
        description:
          '꿈을 통해 무의식을 탐구하고 자아 인식을 높이는 긍정적인 효과를 경험',
      },
    ],
    images: {
      main: 'dreamus_main',
      sub: ['dreamus_sub1', 'dreamus_sub2'],
    },
  },
  {
    id: 4,
    title: 'LG에너지솔루션',
    subtitle: '인터배터리 MR/AR',
    year: '2023',
    tags: ['#MR', '#AR', '#박람회', '#체험 전시'],
    description: `LG에너지솔루션의 혁신적인 배터리 기술을 직관적으로 체험할 수 있는 인터랙티브 AR/MR 체험 전시형 프로젝트로 설계됐습니다.
      2023 인터배터리 박람회에서 선보인 이 프로젝트는 빅휘시커뮤니케이션과의 협업을 통해, 최첨단 배터리 기술을 일반 대중이 쉽게 이해하고 체험할 수 있도록 구현되었습니다. 
      갤럭시 태블릿을 활용한 AR 앱과 HoloLens2를 통한 MR 앱은 배터리의 구조와 작동 원리를 실감나게 보여주며, 관람객들이 능동적으로 상호작용할 수 있는 몰입형 경험을 제공합니다. 
      각 디바이스를 5대씩 전략적으로 배치하여 다수의 관람객이 동시에 체험할 수 있도록 설계되었으며, 이벤트와 연계한 경품 제공으로 관람객들의 참여를 더욱 활성화했습니다. 
      복잡한 기술을 친근하고 이해하기 쉽게 전달함으로써 관람객들의 높은 호응을 이끌어냈으며, 전시장의 흥행을 성공적으로 이끌어낸 혁신적인 체험형 전시 솔루션입니다.`,
    platform: ['Android', 'Hololens'],
    features: [
      {
        title: ' AR 배터리 시각화',
        description:
          '갤럭시 태블릿을 통해 배터리의 구조와 작동 원리를 증강현실로 구현',
      },
      {
        title: 'MR 인터랙티브 체험',
        description:
          'HoloLens2를 활용한 혼합현실 기반의 배터리 기술 체험 환경 제공',
      },
      {
        title: '이벤트 연계 시스템',
        description: '체험과 연동된 경품 이벤트 운영 기능',
      },
    ],
    results: [
      {
        title: '전시 효과',
        description: '체험형 콘텐츠를 통한 전시장 방문객 수 증가 달성',
      },
      {
        title: '협업 성과',
        description:
          'G에너지솔루션, 빅휘시커뮤니케이션과의 성공적인 협업 프로젝트 완수',
      },
      {
        title: '전시 흥행',
        description: '체험형 콘텐츠를 통한 전시 공간의 활성화 달성',
      },
    ],
    images: {
      main: 'lgenergy_main',
      sub: ['lgenergy_sub1', 'lgenergy_sub2'],
    },
  },
  {
    id: 5,
    title: '에코프로비엠',
    subtitle: 'VR 안전교육 솔루션',
    year: '2024',
    tags: ['#VR', '#안전교육', '#볼류메트릭', '#디지털 트윈', '#솔루션'],
    description: `에코프로비엠을 위해 개발된 종합 VR 안전교육 솔루션입니다. 이 프로젝트는 산업 현장의 11가지 주요 안전사고 시나리오를 실감나게 구현하여, 심폐소생술부터 화학 화상 사고 예방까지 광범위한 안전교육을 제공합니다. 
      현장 기반의 디지털 트윈 환경을 완벽히 구현하여 비숙련 근로자들이 안전한 환경에서 위험 상황을 체험하고 대처 방법을 학습할 수 있도록 설계되었습니다. 
      특히 20~40대의 VR 기기를 동시에 운영하고 모니터링할 수 있는 관리자용 태블릿 서버 앱을 통해, 대규모 단체 교육의 효율성을 극대화했습니다. 
      디엔소프트는 초기 기획부터 볼류메트릭 촬영, VR 앱 개발 및 최적화, 하드웨어 납품까지 전 과정을 턴키 방식으로 제공했으며, 에코프로비엠의 현장 전문성과 결합하여 높은 교육 효과를 달성했습니다. 
      이 솔루션의 우수성은 에코프로비엠 계열사로의 확장과 해외 지사 도입 검토로 이어지며, 산업 안전교육의 새로운 표준을 제시하고 있습니다.`,
    platform: ['Android', 'Meta(Oculus)'],
    features: [
      {
        title: '11개 안전교육 시나리오',
        description: '심폐소생술, LOTO, 추락사고 등 현장 중심 VR 교육 콘텐츠',
      },
      {
        title: '관리자 모니터링 시스템',
        description: '태블릿 서버 앱을 통한 20~40대 VR 기기 실시간 관리',
      },
      {
        title: '디지털 트윈 환경',
        description: '실제 공장을 기반으로 한 고품질 교육 환경 구현',
      },

      {
        title: '대규모 동시 교육',
        description: '회의실 규모 공간에서 최대 40명 동시 교육 지원',
      },
    ],
    results: [
      {
        title: '솔루션 완성도',
        description: '하드웨어부터 소프트웨어까지 완벽한 턴키 솔루션 제공',
      },
      {
        title: '교육 효과',
        description: '실감형 콘텐츠를 통한 높은 교육 효과성 입증',
      },
      {
        title: '추가 계약',
        description: '우수한 성과를 바탕으로 에코프로비엠과의 추가 계약 체결',
      },
      {
        title: '사업 확장',
        description:
          '에코프로이엠 계열사 추가 도입 및 해외 지사 또한 도입 검토 예정',
      },
    ],
    images: {
      main: 'ecopro_main',
      sub: ['ecopro_sub1', 'ecopro_sub2'],
    },
  },
  {
    id: 6,
    title: '크레버스(청담러닝)',
    subtitle: '에듀테크 솔루션',
    year: '2024',
    tags: ['#에듀테크', '#VR', '#AR', '#게이미피케이션', '#메타버스'],
    description: `크레버스(구 청담러닝)와 협력하여 개발된 혁신적인 영어교육 솔루션입니다. 
      유아교육 브랜드 i-GARTEN을 위해 AR 기반의 i-Story, VR 기반의 VR-Trip, PC 기반의 Virtual Trip, Role Play, ViP 등 다양한 플랫폼의 교육 앱을 개발했으며,
      초등교육 브랜드 April을 위해 April-Buff와 April-MeMe 앱을 제작했습니다. 
      각 앱은 첨단 기술을 활용하여 아이들이 영어를 자연스럽게 접하고 즐겁게 학습할 수 있도록 설계되었습니다. 
      특히 코로나19 시기에 비대면 교육의 핵심 솔루션으로 자리매김하며 크레버스의 기업 가치 상승에 크게 기여했으며, 이를 바탕으로 지속적인 협력 관계를 유지하며 추가 개발을 논의하고 있습니다.`,
    platform: ['Windows', 'Android', 'iOS'],
    features: [
      {
        title: 'AI 기반 학습',
        description: 'i-GARTEN i-Story를 통한 증강현실 영어 학습 환경 제공',
      },
      {
        title: 'VR 체험 교육',
        description: 'VR-Trip을 통한 몰입형 가상현실 영어 학습',
      },
      {
        title: 'PC 교육 플랫폼',
        description: 'Virtual Trip, Role Play, ViP 등 다양한 PC 기반 학습 도구',
      },
      {
        title: '연령별 맞춤 앱',
        description: '유아용 i-GARTEN 시리즈와 초등용 April 시리즈 구분 개발',
      },
    ],
    results: [
      {
        title: '비대면 교육 성과',
        description: '코로나19 시기 온라인 교육 성공적 전환 달성',
      },
      {
        title: '기업 가치 상승',
        description: '솔루션 도입 후 크레버스 주가 100% 이상 상승',
      },
      {
        title: '지속적 협력',
        description: '추가 앱 개발 논의 등 파트너십 강화',
      },
      {
        title: '교육 효과',
        description: '다양한 플랫폼을 통한 효과적인 영어 학습 환경 구축',
      },
    ],
    images: {
      main: 'creverse_main',
      sub: ['creverse_sub1', 'creverse_sub2'],
    },
  },
  {
    id: 7,
    title: '유니버셜스튜디오',
    subtitle: '미니언즈 AR',
    year: '2021',
    class: 'universal',
    tags: ['#AR', '#애니메이션', '#공간인식'],
    description: `LG유플러스와 유니버셜스튜디오가 협업한 증강현실 엔터테인먼트 프로젝트입니다. 
      전 세계적으로 사랑받는 미니언즈 캐릭터를 모바일 AR 환경에서 생동감 있게 구현하여, 사용자들에게 새로운 경험을 제공합니다. 
      첨단 공간인식 시스템을 활용하여 미니언즈의 다양한 애니메이션을 실제 공간에 자연스럽게 구현했으며, 실시간 그림자 효과와 환경 조명 시스템을 통해 현실감 있는 AR 경험을 제공합니다. 
      유니버셜스튜디오의 오리지널 미니언즈 모델을 기반으로 한 정교한 리터칭과 애니메이션 작업을 통해 캐릭터의 본질을 완벽하게 구현했으며, 이러한 기술력을 인정받아 신비아파트 IP 개발로까지 사업이 확장되었습니다.`,
    platform: ['Android', 'iOS'],
    features: [
      {
        title: '공간인식 AR',
        description: '실제 환경에 자연스럽게 통합되는 미니언즈 캐릭터 구현',
      },
      {
        title: '실감형 렌더링',
        description: '실시간 그림자 효과와 환경 조명 적용 시스템',
      },
      {
        title: '캐릭터 애니메이션',
        description: '미니언즈의 특징적인 동작과 표정을 담은 다양한 모션',
      },
      {
        title: '모바일 최적화',
        description: 'LG유플러스 AR 플랫폼에 최적화된 콘텐츠 구현',
      },
    ],
    results: [
      {
        title: '사업 확장',
        description: '신비아파트 IP 추가 개발 의뢰 획득',
      },
      {
        title: '품질 달성',
        description: '유니버셜스튜디오의 품질 기준 충족',
      },
      {
        title: '플랫폼 안정화',
        description: 'LG유플러스 AR 플랫폼의 대표 콘텐츠로 자리매김',
      },
    ],
    images: {
      main: 'universal_main',
      sub: ['universal_sub1', 'universal_sub2'],
    },
  },
  {
    id: 8,
    title: '육군사관학교',
    subtitle: 'MR 엔지니어링 솔루션',
    year: '2024',
    tags: ['#MR', '#에듀케이션', '#공간인식'],
    description: `육군사관학교를 위해 개발된 첨단 혼합현실 교육 플랫폼입니다. 
      HoloLens를 활용한 이 솔루션은 최대 1명의 교수자와 5명의 학습자가 동시에 참여할 수 있으며, 군사 장비와 기술에 대한 실감형 교육을 제공합니다. 
      교수자는 전용 제어 시스템을 통해 교육 콘텐츠를 효과적으로 조작하고 설명할 수 있으며, 학습자들은 고품질의 3D 홀로그램을 통해 실제와 같은 학습 경험을 얻을 수 있습니다. 
      근거리 네트워크 기반의 안전한 통신 시스템을 구축하여 군사 교육 환경에 최적화되어 있으며, K2 소총, 자동차 구조, 아파치 헬기 등 다양한 군사 장비에 대한 상세한 교육 콘텐츠를 제공합니다.`,
    platform: ['Hololens'],
    features: [
      {
        title: '다중 사용자 지원',
        description: '최대 6명 동시 접속 가능한 교육 환경',
      },
      {
        title: '교수자 제어 시스템',
        description: '실시간 콘텐츠 제어 및 교육 진행 기능',
      },
      {
        title: '보안 네트워크',
        description: '근거리 통신 기반의 폐쇄형 네트워크 구축',
      },
      {
        title: '실감형 콘텐츠',
        description: 'K2 소총 분해조립, 차량 구조, 헬기 시뮬레이션 등 구현',
      },
    ],
    results: [
      {
        title: '지속적 협력',
        description: '육군사관학교와의 매년 정기적인 사업 진행',
      },
      {
        title: '콘텐츠 확장',
        description: '지속적인 교육 콘텐츠 추가 개발 진행',
      },

      {
        title: '신뢰도 확보',
        description: '군사 교육 분야에서의 기술력 인정',
      },
      {
        title: '교육 혁신',
        description: '첨단 MR 기술을 활용한 군사 교육 패러다임 변화 주도',
      },
    ],
    images: {
      main: 'kma_main',
      sub: ['kma_sub1', 'kma_sub2'],
    },
  },
  {
    id: 9,
    title: 'NIPA',
    subtitle: '5G콘텐츠 시연 솔루션',
    year: '2023',
    tags: ['#MR', '#무대 연출', '#볼류메트릭'],
    description: `NIPA의 5G 실감형 콘텐츠 지원사업으로 개발된 혁신적인 공연 무대 설계 플랫폼입니다. 
      K-pop 산업의 급속한 성장에 따른 무대 설계 수요에 대응하여, HoloLens의 6DoF 기술과 공간인식 시스템을 활용해 실시간으로 무대를 구성하고 시뮬레이션할 수 있습니다. 
      사용자는 다양한 무대 소품을 자유롭게 배치하고 조절할 수 있으며, 볼류메트릭 촬영으로 제작된 실제 아티스트의 홀로그램을 무대에 통합하여 완성도 높은 무대를 구현할 수 있습니다. 
      모든 콘텐츠 업로드/다운로드 관리는 웹페이지 이루어지며, 이러한 관리시스템의 모든 기능과 서버까지 도맡아서 구현하였습니다.
      HoloLens에서 대용량 홀로그램 콘텐츠의 원활한 다운로드와 화면 스트리밍을 위한 5G 네트워크 최적화를 성공적으로 달성하였고 차세대 공연 기획의 새로운 표준을 제시했습니다.`,
    platform: ['Hololens', 'Web'],
    features: [
      {
        title: '실시간 무대 설계',
        description: '무대 소품의 자유로운 배치, 회전, 크기 조절 기능',
      },
      {
        title: '공간인식 시스템',
        description: 'HoloLens 6DoF 기술 기반의 정밀한 공간 매핑',
      },
      {
        title: '홀로그램 통합',
        description: '볼류메트릭 촬영 아티스트의 실감형 홀로그램 배치',
      },
      {
        title: '5G 스트리밍',
        description: '대용량 콘텐츠의 원활한 실시간 스트리밍 구현',
      },
    ],
    results: [
      {
        title: '기술 목표 달성',
        description: '5G 기반 대용량 콘텐츠 스트리밍 최적화 성공',
      },
      {
        title: '산업 수요 대응',
        description: 'K-pop 공연 기획 분야의 효율적 솔루션 제공',
      },
      {
        title: '사업 완수',
        description: 'NIPA 지원사업의 성공적 수행 및 완료',
      },
      {
        title: '시장 가능성',
        description: '성장하는 K-pop 산업에 특화된 솔루션 개발',
      },
    ],
    images: {
      main: 'nipa_main',
      sub: ['nipa_sub1', 'nipa_sub2'],
    },
  },
  {
    id: 10,
    title: '수원시립미술관',
    subtitle: '스마트수원시립미술관 VR솔루션',
    year: '2023',
    tags: ['#VR', '#AR', '#체험 전시'],
    description: `수원시립미술관과 협업하여 개발된 혁신적인 디지털 전시 플랫폼입니다. 나혜석 작가를 주제로 한 이 프로젝트는 VR 체험과 AR 전시해설을 통합하여 관람객들에게 새로운 차원의 미술 감상 경험을 제공합니다. VR 체험존은 3대의 VR 기기와 55인치 미러링 디스플레이를 통해 관람객의 실시간 작품 감상 모습을 공유하며, Android 태블릿 기반의 AR 도슨트 앱은 작품에 대한 상세한 해설을 제공합니다. 전시 자료 기획부터 3D 모델링, 하드웨어 납품까지 전 과정을 턴키 방식으로 완수하여 미술관의 디지털 혁신을 성공적으로 이끌었습니다.`,
    platform: ['Android', 'Meta(Oculus)'],
    features: [
      {
        title: 'VR 전시 체험',
        description: '3대의 VR 기기를 통한 몰입형 작품 감상',
      },
      {
        title: '실시간 미러링',
        description: '55인치 대형 디스플레이로 관람 경험 공유',
      },
      {
        title: 'AR 도슨트',
        description: '태블릿 기반의 증강현실 작품 해설 시스템',
      },
      {
        title: '통합 운영 시스템',
        description: 'VR/AR 콘텐츠의 효율적 관리 및 운영',
      },
    ],
    results: [
      {
        title: '관람객 증가',
        description: '혁신적 체험 요소를 통한 미술관 방문객 증대',
      },
      {
        title: '추가 개발 의뢰',
        description: 'AR 앱 분석 지표 개발 등 후속 프로젝트 수주',
      },
      {
        title: '디지털 전환',
        description: '전통적 미술관의 성공적인 스마트 뮤지엄 전환',
      },
      {
        title: '턴키 솔루션',
        description:
          '기획부터 리소스제작, 개발, 하드웨어 납품까지 완벽한 원스톱 솔루션 제공',
      },
    ],
    images: {
      main: 'suwon_main',
      sub: ['suwon_sub1', 'suwon_sub2'],
    },
  },
  {
    id: 11,
    title: '영신디엔씨',
    subtitle: '토공 AI 솔루션',
    year: '2022',
    class: 'youngshine',
    tags: ['#AI', '#머신러닝', '#토목공사', '솔루션'],
    description: `영신디엔씨와 협력하여 개발된 머신러닝 기반의 혁신적인 토공 비용 예측 솔루션입니다. 실제 토공 현장의 데이터를 기반으로, 유니티 ML 에이전트를 활용하여 100만회 이상의 보상 학습을 통해 정교한 예측 모델을 구축했습니다. 사용자가 지형 데이터, 장비 구성, 현장 조건 등의 핵심 정보를 입력하면 최신형 워크스테이션을 통해 토공 작업의 예상 비용을 산출합니다. R&D 프로젝트로서 미래 토공 산업의 디지털 전환을 위한 혁신적인 방향성을 제시하며, 지속적인 발전 가능성을 보여주고 있습니다.`,
    platform: ['Windows'],
    features: [
      {
        title: '머신러닝 예측',
        description: '100만회 이상의 보상 학습 기반 비용 예측 모델',
      },
      {
        title: '데이터 입력 시스템',
        description: '지형, 장비, 현장 조건 등 핵심 정보 처리',
      },
      {
        title: '비용 산출',
        description: '워크스테이션 기반의 정교한 토공 비용 계산',
      },
      {
        title: '시뮬레이션',
        description: '다양한 현장 조건에 따른 비용 변동 분석',
      },
    ],
    results: [
      {
        title: '기술 혁신',
        description: '토공 산업의 디지털 전환 방향성 제시',
      },
      {
        title: '모델 신뢰성',
        description: '실제 현장 데이터 기반의 학습 모델 구축',
      },
      {
        title: '발전 가능성',
        description: '지속적인 개선과 확장이 가능한 R&D 성과 도출',
      },
      {
        title: '산업 기여',
        description: '토공 비용 예측의 새로운 패러다임 제시',
      },
    ],
    images: {
      main: 'youngshine_main',
      sub: ['youngshine_sub1', 'youngshine_sub2'],
    },
  },
  {
    id: 12,
    title: '건국대학교',
    subtitle: 'Holo-Lecture',
    year: '2022',
    class: 'ku',
    tags: ['#MR', '#에듀케이션', '#공간인식', '#솔루션'],
    description: `건국대학교 교수학습센터와 협력하여 개발된 교수법 시뮬레이션 및 분석 플랫폼입니다. 
      HoloLens를 활용한 이 솔루션은 교수자들이 다양한 교육 상황을 실제와 같이 체험하고, 이를 녹화하여 전문적인 컨설팅을 받을 수 있도록 설계되었습니다. 
      서버 PC의 관리자 앱을 통해 교육 시나리오를 맞춤 설정할 수 있으며, 실시간 스트리밍과 녹화 기능으로 교수법 분석이 가능합니다. 
      건국대학교의 교육 시나리오 가이드라인을 바탕으로, 기획부터 개발, 시연까지 전 과정을 성공적으로 수행했으며, 현재 생성형 AI 기반의 시나리오 확장을 논의하며 지속적인 발전을 도모하고 있습니다.`,
    platform: ['Hololens'],
    features: [
      {
        title: 'MR 시뮬레이션',
        description: 'HoloLens 기반의 실감형 교육 상황 체험',
      },
      {
        title: '실시간 모니터링',
        description: 'PC 관리자 앱을 통한 교육 과정 스트리밍',
      },
      {
        title: '녹화 분석',
        description: '교수법 개선을 위한 수업 녹화 및 분석 시스템',
      },
      {
        title: '시나리오 커스터마이징',
        description: '다양한 교육 상황 설정 및 관리 기능',
      },
    ],
    results: [
      {
        title: '교육 혁신',
        description: '건국대학교 교수진의 교수법 향상에 기여',
      },
      {
        title: '사용자 만족',
        description: '교수진들의 높은 활용도 및 긍정적 피드백 획득',
      },
      {
        title: '확장 가능성',
        description: '생성형 AI 도입 등 추가 개발 논의 진행',
      },
      {
        title: '턴키 솔루션',
        description: '기획부터 시연까지 완벽한 프로젝트 수행',
      },
    ],
    images: {
      main: 'ku_main',
      sub: ['ku_sub1', 'ku_sub2'],
    },
  },
  {
    id: 13,
    title: 'LG유플러스',
    subtitle: '신비아파트 XR 전시관',
    year: '2021',
    class: 'shinbi',
    tags: ['#VR', '#메타버스', '#게이미피케이션'],
    description: `LG유플러스가 CJ ENM과 IP계약을 통해 디엔소프트와 협력하여 개발된 신비아파트 IP 기반의 인터랙티브 XR 전시관입니다. Android VR 플랫폼을 기반으로 제작된 이 솔루션은 신비아파트의 인기 캐릭터들과 실시간으로 상호작용할 수 있는 몰입형 경험을 제공합니다. 전시관 곳곳에 배치된 귀신들과 주인공 캐릭터들은 방문자들과 다양한 인터랙션을 할 수 있으며, AI 챗봇 시스템을 통해 캐릭터들과의 실감나는 대화도 가능합니다. LG유플러스의 플랫폼을 통해 서비스되는 이 콘텐츠는 사용자들에게 높은 호응을 얻으며 성공적으로 운영되고 있습니다.`,
    platform: ['Android'],
    features: [
      {
        title: 'VR 캐릭터 인터랙션',
        description: '신비아파트 캐릭터들과의 실시간 상호작용',
      },
      {
        title: 'AI 대화 시스템',
        description: '캐릭터들과의 자연스러운 대화 기능 구현',
      },
      {
        title: '몰입형 환경',
        description: 'VR 기술을 활용한 실감나는 전시관 체험',
      },
      {
        title: '플랫폼 최적화',
        description: 'LG유플러스 Android VR 플랫폼 맞춤 구현',
      },
    ],
    results: [
      {
        title: '사용자 만족도',
        description: '높은 사용자 호응도 및 긍정적 피드백 획득',
      },
      {
        title: '플랫폼 안정화',
        description: 'LG유플러스 VR 플랫폼 성공적 런칭',
      },
      {
        title: 'IP 활용',
        description: '신비아파트 IP의 효과적인 XR 콘텐츠화 달성',
      },
      {
        title: '기술 구현',
        description: 'VR과 AI 챗봇의 효과적인 융합 실현',
      },
    ],
    images: {
      main: 'shinbi_main',
      sub: ['shinbi_sub1', 'shinbi_sub2'],
    },
  },
  {
    id: 14,
    title: 'KT',
    subtitle: '스펠 오브 다이노 VR',
    year: '2021',
    class: 'kt',
    tags: ['#VR', '#에듀케이션', '#게이미피케이션'],
    description: `KT와 협력하여 개발된 교육용 VR 콘텐츠로, 아이들이 마법사가 되어 공룡 세계를 탐험하는 독특한 학습 경험을 제공합니다. 이 프로젝트는 KT 슈퍼VR 서비스의 일환으로, 실제 크기의 공룡들과 직접 상호작용하며 생태를 학습할 수 있는 몰입형 교육 콘텐츠입니다. 마법천자문 개정판의 한자 학습 요소를 결합하여, 책 페이지와 한자 카드 인식 기능을 통해 교육적 가치를 높였으며, 6~12세 아동을 대상으로 총 6편, 약 60분 분량의 풍부한 학습 콘텐츠를 제공합니다. Oculus, Vive, Pico 등 다양한 VR 플랫폼을 지원하여 접근성을 극대화했습니다.`,
    platform: ['Android'],
    features: [
      {
        title: '스토리 기반 학습',
        description: '마법사 성장 스토리를 통한 공룡 생태 교육',
      },
      {
        title: '실감형 공룡 체험',
        description: '실제 크기의 공룡들과 직접 상호 작용',
      },
      {
        title: 'AR 한자 학습',
        description: '마법천자문 도서 인식 및 한자 카드 인터랙션',
      },
      {
        title: '멀티 플랫폼 지원',
        description: 'Oculus, Vive, Pico 등 다양한 VR 기기 호환',
      },
    ],
    results: [
      {
        title: '교육 효과',
        description: '재미와 학습을 결합한 효과적인 교육 콘텐츠 구현',
      },
      {
        title: '플랫폼 확장',
        description: '다양한 VR 플랫폼 지원으로 높은 접근성 확보',
      },
      {
        title: '콘텐츠 완성도',
        description: '총 6편의 체계적인 교육 시리즈 개발',
      },
      {
        title: '연령 맞춤화',
        description: '6~12세 아동의 특성을 고려한 최적화된 콘텐츠 제작',
      },
    ],
    images: {
      main: 'kt_main',
      sub: ['kt_sub1', 'kt_sub2'],
    },
  },
  {
    id: 15,
    title: 'SKT',
    subtitle: 'JumpStudio AR/MR',
    year: '2020',
    class: 'skt',
    tags: ['#AR/MR', '#디지털 트윈', '#볼류메트릭'],
    description: `SKT의 JumpStudio 서비스를 위해 개발된 볼류메트릭 콘텐츠 관리 솔루션입니다. 이 프로젝트는 볼류메트릭 콘텐츠의 업로드부터 검수, 최종 납품까지의 전 과정을 효율적으로 관리할 수 있도록 설계되었습니다. Android/iOS 플랫폼은 물론 HoloLens까지 지원하여 더욱 실감나는 콘텐츠 검토가 가능하며, 제작자들이 간편하게 볼류메트릭 결과물을 관리하고 검토할 수 있는 전문가용 도구로서 SKT의 콘텐츠 제작 파이프라인에서 핵심적인 역할을 수행하고 있습니다.`,
    platform: ['Android', 'iOS', 'Hololens'],
    features: [
      {
        title: '볼류메트릭 프리뷰',
        description: '실시간 콘테느 검토 및 품질 확인',
      },
      {
        title: '멀티 플랫폼 지원',
        description: 'Android, iOS, HoloLens 통합 지원',
      },
      {
        title: '서버 관리',
        description: '효율적인 콘텐츠 업로드 및 관리 시스템',
      },
      {
        title: 'JumpStudio 연동',
        description: '검수 완료된 콘텐츠의 원활한 납품 프로세스',
      },
    ],
    results: [
      {
        title: '워크플로우 최적화',
        description: '볼류메트릭 콘텐츠 관리 프로세스 효율화',
      },
      {
        title: '플랫폼 확장',
        description: 'HoloLens 지원으로 몰입도 높은 검수 환경 구축',
      },
      {
        title: '지속적 활용',
        description: 'SKT의 볼류메트릭 콘텐츠 관리 도구로 안정적 운영',
      },

      {
        title: '생산성 향상',
        description: '신속한 콘텐츠 검토와 관리로 제작 효율성 증대',
      },
    ],
    images: {
      main: 'skt_main',
      sub: ['skt_sub1', 'skt_sub2'],
    },
  },
  {
    id: 16,
    title: '대전시',
    subtitle: '테미오래 AR',
    year: '2022',
    tags: ['#AR', '#도슨트', '#공간인식'],
    description: `대전시 테미오래의 관람 경험을 혁신적으로 개선하기 위해 개발된 인터랙티브 도슨트 앱입니다. 현장 곳곳에 설치된 QR 코드를 기반으로, 방문객들이 테미오래의 역사적 공간을 스토리텔링과 미션을 통해 흥미롭게 탐험할 수 있도록 설계되었습니다. 초기 기획부터 리소스 제작, 개발, 현장 테스트까지 전 과정을 자체적으로 수행하여 완성도 높은 관람 안내 시스템을 구축했으며, Android와 iOS 플랫폼을 통해 누구나 쉽게 접근할 수 있는 공공 서비스로 자리매김했습니다.`,
    platform: ['Android', 'iOS'],
    features: [
      {
        title: 'QR 코드 안내',
        description: '현장 위치 기반의 세부 지역 정보 제공',
      },
      {
        title: '스토리텔링',
        description: '흥미로운 내러티브를 통한 공간 해설',
      },
      {
        title: '미션 시스템',
        description: '관람객 참여를 유도하는 인터랙티브 요소',
      },
      {
        title: '크로스 플랫폼',
        description: 'Android/iOS 앱스토어 통합 지원',
      },
    ],
    results: [
      {
        title: '관람 활성화',
        description: '역동적인 관람 경험을 통한 방문객 만족도 향상',
      },
      {
        title: '접근성 확보',
        description: '주요 앱스토어를 통한 편리한 서비스 제공',
      },
      {
        title: '현장 최적화',
        description: '실제 환경에 맞춘 안정적인 운영 시스템 구축',
      },
      {
        title: '공공 서비스',
        description: '지역 문화유산의 효과적인 디지털 안내 솔루션 확립',
      },
    ],
    images: {
      main: 'daejeon_main',
      sub: ['daejeon_sub1', 'daejeon_sub2'],
    },
  },
  {
    id: 17,
    title: '극동대학교',
    subtitle: '미숙아 교육 솔루션',
    year: '2022',
    tags: ['#AR', '#MR', '#에듀케이션', '#공간인식'],
    description: `극동대학교와 협력하여 개발된 미숙아 케어 교육용 MR 솔루션입니다. 첫 해에는 아기돌보기 3종과 이른둥이 특수간호 1종의 콘텐츠를 개발하여 큰 호응을 얻었으며, 2차년도에는 추가로 8종의 콘텐츠를 개발하여 총 12종의 종합적인 교육 프로그램으로 확장되었습니다. 극동대학교의 전문적인 시나리오를 바탕으로 실감나는 MR 콘텐츠를 구현했으며, HoloLens와 Android 태블릿을 모두 지원하여 다양한 환경에서 효과적인 실습 교육이 가능하도록 구축되었습니다.`,
    platform: ['Hololens', 'Android'],
    features: [
      {
        title: '실감형 케어 실습',
        description: 'MR 기술을 활용한 미숙아 케어 시뮬레이션',
      },
      {
        title: '다양한 교육 모듈',
        description: '아기돌보기 6종, 이른둥이 특수간호 6종 구현',
      },
      {
        title: '멀티 플랫폼',
        description: 'HoloLens와 Android 태블릿 크로스 플랫폼 지원',
      },
      {
        title: '전문 교육 콘텐츠',
        description: '현장 전문가의 시나리오 기반 실습 환경',
      },
    ],
    results: [
      {
        title: '교육 확장',
        description: '1차년도 4종에서 2차년도 12종으로 콘텐츠 확대',
      },
      {
        title: '플랫폼 다각화',
        description: 'Android 태블릿 지원으로 접근성 향상',
      },
      {
        title: '교육 효과',
        description: '실감형 콘텐츠를 통한 높은 학습 효과성 입증',
      },
      {
        title: '지속적 발전',
        description: '2년 연속 사업 진행으로 교육 시스템 고도화',
      },
    ],
    images: {
      main: 'kdu_main',
      sub: ['kdu_sub1', 'kdu_sub2'],
    },
  },
  {
    id: 18,
    title: '국민은행',
    subtitle: 'AI 컨설팅 솔루션',
    year: '2023',
    tags: ['#AI', '#MR', '#에듀케이션', '#공간인식'],
    description: `국민은행과 협력하여 개발된 은행원 교육용 MR 프로젝트입니다. 국민은행이 제공하는 생성형 AI와 서버 인프라를 기반으로, 신입 은행원들이 HoloLens를 통해 실제 고객 응대 상황을 실습하고 금융 상품 판매 절차를 학습할 수 있도록 설계되었습니다. AI 챗봇과의 실시간 상호작용을 통해 상품 지식과 판매 스킬을 향상시킬 수 있으며, 실전과 같은 환경에서 안전하게 영업 역량을 강화할 수 있는 혁신적인 교육 플랫폼입니다.`,
    platform: ['Hololens'],
    features: [
      {
        title: 'AI 기반 상담 훈련',
        description: '생성형 AI를 활용한 실감형 고객 응대 실습',
      },
      {
        title: '상품 교육 시스템',
        description: '금융 상품의 이해와 판매 절차 학습',
      },
      {
        title: '실시간 피드백',
        description: 'AI 챗봇을 통한 즉각적인 컨설팅 제공',
      },
      {
        title: 'MR 시뮬레이션',
        description: 'HoloLens 기반의 실전 영업 환경 구현',
      },
    ],
    results: [
      {
        title: '교육 효율성',
        description: '금융 상품 이해도와 판매 역량 향상',
      },
      {
        title: '혁신적 접근',
        description: 'AI와 MR 기술의 성공적인 교육 분야 적용',
      },
      {
        title: '실무 적용성',
        description: '실제 영업 환경과 유사한 실습 경험 제공',
      },
      {
        title: '기술 통합',
        description: '생성형 AI와 MR의 효과적인 결합 구현',
      },
    ],
    images: {
      main: 'kb_main',
      sub: ['kb_sub1', 'kb_sub2'],
    },
  },
  {
    id: 19,
    title: '이마트',
    subtitle: '웹 AR 이벤트 솔루션',
    year: '2021',
    tags: ['#AR', '#게이미피케이션', '#공간인식'],
    description: `이마트24의 쓱데이 프로모션을 위해 개발된 웹 기반 AR 게임 플랫폼입니다. 이 프로젝트는 음악 저작권 이벤트와 연계하여, 사용자들이 매장 내 특정 상품과 캐릭터를 스캔하여 즐길 수 있는 인터랙티브 AR 경험을 제공합니다. 슈팅게임과 점핑게임 두 가지 모드를 통해 레트로 감성을 살린 게임플레이를 구현했으며, 모바일 웹 환경에서 원활하게 작동하도록 최적화되었습니다. 특히 이마트24의 새로운 브랜드 캐릭터인 '딜리'와 '셜리'를 활용하여 브랜드 아이덴티티를 효과적으로 강화했습니다.`,
    platform: ['Web'],
    features: [
      {
        title: '상품 인식 AR',
        description: '특정 상품 스캔을 통한 게임 활성화 시스템',
      },
      {
        title: '듀얼 게임모드',
        description: '슈팅게임과 점핑게임 선택 옵션 제공',
      },
      {
        title: '캐릭터 인터랙션',
        description: '브랜드 캐릭터와의 AR 상호작용',
      },
      {
        title: '이벤트 연동',
        description: '게임 미션 완료 시 자동 경품 응모 시스템',
      },
    ],
    results: [
      {
        title: '브랜드 강화',
        description: '차별화된 AR 경험을 통한 브랜드 가치 제고',
      },
      {
        title: '고객 참여',
        description: 'MZ세대 타겟의 인터랙티브 마케팅 성공',
      },
      {
        title: '기술 혁신',
        description: '웹 기반 AR의 효과적인 프로모션 활용 실현',
      },
      {
        title: '마케팅 효과',
        description: '음악 저작권 이벤트와의 시너지 창출',
      },
    ],
    images: {
      main: 'emart_main',
      sub: ['emart_sub1', 'emart_sub2'],
    },
  },
  {
    id: 20,
    title: 'LG유플러스',
    subtitle: 'AI 골목여행 VR',
    year: '2022',
    tags: ['#VR', '#AI', '#도슨트'],
    description: ` LG유플러스와 마음AI가 협력하여 개발된 인공지능 기반의 여행 도슨트 애플리케이션입니다. 마음AI가 제공하는 첨단 AI 아바타 기술을 활용하여 전국의 주요 관광 명소를 상세하게 안내하는 가상 여행 가이드 서비스를 구현했습니다. LG유플러스의 공식 앱 플랫폼에 성공적으로 탑재되어 사용자들에게 깊이 있는 여행 정보와 실시간 상호작용을 제공하며, 스마트한 여행 경험을 선사하고 있습니다.`,
    platform: ['Android'],
    features: [
      {
        title: 'AI 가이드',
        description: '실시간 상호작용이 가능한 AI 아바타 안내 시스템',
      },
      {
        title: '관광지 정보',
        description: '전국 주요 명소에 대한 상세한 해설 제공',
      },
      {
        title: '실시간 응답',
        description: '사용자 질문에 대한 즉각적인 AI 피드백',
      },
    ],
    results: [
      {
        title: '서비스 안정화',
        description: 'LG유플러스 공식 플랫폼 성공적 런칭',
      },
      {
        title: '기술 융합',
        description: 'AI 아바타 기술의 효과적인 도슨트 서비스 구현',
      },
      {
        title: '사용자 경험',
        description: '직관적이고 몰입도 높은 여행 가이드 제공',
      },
      {
        title: '협업 성과',
        description: '마음AI와의 성공적인 기술 협력 달성',
      },
    ],
    images: {
      main: 'lgu_main',
      sub: ['lgu_sub1', 'lgu_sub2'],
    },
  },
  {
    id: 21,
    title: '천재교육',
    subtitle: '에듀XR 콘텐츠',
    year: '2019',
    tags: ['#MR', '#에듀테크', '#공간인식'],
    description: `천재교육과 협력하여 개발된 교육용 MR 플랫폼입니다. HoloLens1 출시 초기에 시작된 이 혁신적인 프로젝트는 다양한 교과 과정을 실감형 MR 콘텐츠로 구현하여 학습 효과를 극대화했습니다. 1차년도에는 우주의 기원과 인류 진화를 주제로 한 콘텐츠를 개발했으며, 2차년도에는 세계 문화 15종, 식물 구조 4종, 구름의 원리 3종, 염색체의 원리 3종, 영양소의 소화와 흡수 2종 등 더욱 다양한 교육 콘텐츠를 제작했습니다. 특히 PC와 HoloLens 간의 콘텐츠 동기화 시스템을 구축하여 더욱 효율적인 교육 환경을 조성했습니다.`,
    platform: ['Hololens'],
    features: [
      {
        title: '실감형 교육',
        description: 'MR 기술을 활용한 몰입도 높은 학습 경험',
      },
      {
        title: '멀티 플랫폼',
        description: 'PC와 HoloLens 연동 시스템 구축',
      },
      {
        title: '다양한 콘텐츠',
        description: '과학, 문화, 생물학 등 폭넓은 교육 주제',
      },
      {
        title: '인터랙티브 학습',
        description: '학생들의 능동적 참여를 유도하는 교육 방식',
      },
    ],
    results: [
      {
        title: '교육 혁신',
        description: 'MR 기술을 통한 새로운 교육 패러다임 제시',
      },
      {
        title: '사업 확장',
        description: '1차년도 성공을 바탕으로 2차년도 규모 확대',
      },
      {
        title: '학습 효과',
        description: '학생들의 높은 만족도와 학습 효율성 입증',
      },
      {
        title: '지속적 협력',
        description: '천재교육과의 장기적 파트너십 구축',
      },
    ],
    images: {
      thumbnail: 'chunjae_xr_thumbnail',
      main: 'chunjae_xr_main',
      sub: ['chunjae_xr_sub1', 'chunjae_xr_sub2'],
    },
  },
];
