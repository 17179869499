import React from 'react';
import { motion } from 'framer-motion';
import { containerVariants, titleVariants } from '../animations';

const ProjectItem = ({
  title,
  description,
  link,
  imageClass,
  containerVariants,
}) => {
  return (
    <motion.div
      className='project'
      variants={containerVariants}
      initial='hidden'
      whileInView='visible'
      viewport={{ once: true, amount: 0.3 }}>
      <div className='project-text'>
        <div>
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
        <div className='link'>
          <a href={link} target='_blank' rel='noopener noreferrer'>
            {title === 'Holo-Lecture'
              ? '서비스 안내자료'
              : '서비스 바로가기'}
          </a>
        </div>
      </div>
      <motion.div
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className={`project-image ${imageClass}`}
      ></motion.div>
    </motion.div>
  );
};

const Project = () => {
  const projectData = [
    {
      title: '알공 잉글리시플래닛',
      description:
        '전국의 초등학교에서 사용중인 AI 기반 영어 학습 코스웨어로, 메타버스 환경에서 교과서 연계 학습을 제공합니다. 학생들은 AI 튜터와의 상호작용 및 가상 미션 수행을 통해 재미있게 영어를 배우며 교사들은 LMS로 학생들의 학습 진행을 실시간 관리할 수 있습니다.',
      link: 'https://www.argong.ai/',
      imageClass: 'project1',
    },
    {
      title: '마법천자문 공식앱',
      description:
        '200만부 판매의 대한민국 대표 학습만화 시리즈인 마법천자문의 공식 학습앱 서비스입니다. 증강현실(AR) 기능을 활용하여 책과 카드와 연계되는 3D한자마법 수련을 통해 캐릭터 카드와 어휘 카드를 획득하고, 강화된 캐릭터들과 함께 보스를 물리치고 보상을 획득하며 한자학습의 재미를 더합니다.',
      link: 'http://www.magichanja.com/index.php/support/?board_name=support&order_by=fn_reg_date&order_type=desc&list_type=list&vid=25',
      imageClass: 'project2',
    },
    {
      title: 'Holo-Lecture',
      description:
        'Holo-Lecture는 3D 아바타를 홀로그램을 실세계에 투사하여 교수학습 시나리오를 설정하고 교수법과 수업진행을 설계하여 훈련할 수 있는 교수학습 특화 XR 솔루션입니다. 교수자와 관찰자용 AR글래스 앱과 AI 실행 서버로 다양한 상황과 형태의 교수학습 시나리오의 훈련을 제공할 수 있습니다.',
      link: 'dnsoft-holo-lecture-2023.pdf',
      imageClass: 'project3',
    },
  ];

  return (
    <section id='contents' className='project-section'>
      <div className='content inner'>
        <motion.div
          className='title'
          variants={titleVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}>
          <h3>학습의 미래를 제시하는</h3>
          <h3>디엔소프트 오리지널 콘텐츠</h3>
        </motion.div>

        <div className='project-wrap'>
          {projectData.map((project, index) => (
            <ProjectItem
              key={index}
              {...project}
              containerVariants={containerVariants}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
export default Project;
