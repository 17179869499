import React from 'react';
import { motion } from 'framer-motion';
import Main from '../page/Main';
import Portfolio from '../page/Portfolio';
import AIEthics from '../page/AIEthics';
import Privacy from '../page/Privacy';

const pageTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 },
};

export const createRoutes = (isSplashVisible, SplashScreen) => [
  {
    path: '/',
    element: isSplashVisible ? (
      <motion.div key='splash' {...pageTransition}>
        <SplashScreen />
      </motion.div>
    ) : (
      <motion.div key='main' {...pageTransition}>
        <Main />
      </motion.div>
    ),
  },
  {
    path: '/portfolio/:id',
    element: (
      <motion.div {...pageTransition}>
        <Portfolio />
      </motion.div>
    ),
  },
  {
    path: '/ai-ethics',
    element: (
      <motion.div {...pageTransition}>
        <AIEthics />
      </motion.div>
    ),
  },
  {
    path: '/privacy',
    element: (
      <motion.div {...pageTransition}>
        <Privacy />
      </motion.div>
    ),
  },
];
