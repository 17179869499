import React from 'react';
import { useNavigate } from 'react-router-dom';

function AIEthics() {
  const navigate = useNavigate();

  return (
    <div className='privacy-container'>
      <button className='prev-btn' onClick={() => navigate(-1)}></button>
      <button className='close-btn' onClick={() => navigate(-1)}></button>

      <div className='inner'>
        <h3 className='title'>인공지능(AI) 윤리 준수를 위한 노력과 다짐</h3>

        <div className='ethics-content'>
          <h4>
            우리는 인공지능 기술 기업으로서 사회적 책임을 다하기 위해
            기획-개발-운영의 단계에서
            <br />
            다음과 같은 인공지능(AI) 윤리 원칙을 준수하고 모든 구성원이 함께
            노력할 것을 다짐합니다.
          </h4>

          <div className='ethics-sections'>
            <div className='ethics-section'>
              <p>
                <span>하나,</span> 모두에게 포용적인 인공지능 서비스를
                기획합니다.
              </p>
              <ul>
                <li>
                  인간의 권리와 존엄의 가치를 지킬 수 있도록 인간 중심의
                  인공지능 서비스를 기획합니다.
                </li>
                <li>
                  인공지능 기술의 활용 과정에서 예측할 수 있는 위험이 있다면
                  사전에 투명하게 공개합니다.
                </li>
                <li>
                  이용자의 다양성을 고려하고 차별적 요소를 최소화하여 누구라도
                  쉽게 이용할 수 있도록 보편적 설계를 지향합니다.
                </li>
              </ul>
            </div>

            <div className='ethics-section'>
              <p>
                <span>하나,</span> 안전하고 윤리적인 인공지능 기술을 개발합니다.
              </p>
              <ul>
                <li>
                  인공지능 기술이 이용자의 권익을 침해하지 않고 사회적 책임을
                  다할 수 있도록 관련 법령과 지침을 반영하기 위해 노력합니다.
                </li>
                <li>
                  이용자의 프라이버시 보호와 안전성 확보를 우선으로 하고 잠재적
                  위험을 방지하기 위한 기술적 조치를 충분히 고려하여 개발합니다.
                </li>
                <li>
                  인공지능 기술 개발 전 과정에서 협업, 차별 등 데이터 편향과
                  오류를 최소화하기 위해 노력합니다.
                </li>
              </ul>
            </div>

            <div className='ethics-section'>
              <p>
                <span>하나,</span> 인공지능 위험 대응을 위해 협력하고 공익에
                앞장섭니다.
              </p>
              <ul>
                <li>
                  사회 전체의 이익과 문제 해결에 도움이 될 수 있도록 인공지능
                  기술을 운영하고 발전시키기 위해 노력합니다.
                </li>
                <li>
                  인공지능 서비스 운영 개선을 위해 이용자의 의견을 적극 수렴하고
                  다양한 집단과 협력합니다.
                </li>
                <li>
                  인공지능 서비스 운영 중에 권익침해, 오류 등의 위험이 발생할
                  경우를 대비해 보호장치를 마련하고, 사용자와 사회에 사실을
                  알리고 대응 조치를 합니다.
                </li>
              </ul>
            </div>
          </div>

          <h4>
            우리는 인공지능 윤리 원칙을 준수하며 관련 교육 등을 통해
            <br />
            인공지능 기술의 혁신을 통한 국가사회의 윤기능 확산과 역기능 최소화에
            앞장서겠습니다.
          </h4>
        </div>

        <div className='gray-box'>
          <span>
            이 AI윤리 원칙은 한국지능정보사회진흥원(NIA)과 인공지능 기업이 공동
            제작하였으며,
            <br />
            앞으로도 인공지능 기업의 혁신과 사회적 책임 강화를 위해 협력할
            것입니다.
          </span>
        </div>
      </div>
    </div>
  );
}

export default AIEthics;
