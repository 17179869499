const PLATFORM_ICONS = {
    'Android': (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.46745 25.2316C8.46745 25.588 8.32587 25.9298 8.07387 26.1818C7.82187 26.4338 7.48008 26.5754 7.1237 26.5754C6.76731 26.5754 6.42553 26.4338 6.17352 26.1818C5.92152 25.9298 5.77995 25.588 5.77995 25.2316V17.1691C5.77995 16.8127 5.92152 16.4709 6.17352 16.2189C6.42553 15.9669 6.76731 15.8254 7.1237 15.8254C7.48008 15.8254 7.82187 15.9669 8.07387 16.2189C8.32587 16.4709 8.46745 16.8127 8.46745 17.1691V25.2316ZM33.5508 25.2316C33.5508 25.588 33.4092 25.9298 33.1572 26.1818C32.9052 26.4338 32.5634 26.5754 32.207 26.5754C31.8506 26.5754 31.5089 26.4338 31.2569 26.1818C31.0049 25.9298 30.8633 25.588 30.8633 25.2316V17.1691C30.8633 16.8127 31.0049 16.4709 31.2569 16.2189C31.5089 15.9669 31.8506 15.8254 32.207 15.8254C32.5634 15.8254 32.9052 15.9669 33.1572 16.2189C33.4092 16.4709 33.5508 16.8127 33.5508 17.1691V25.2316ZM17.4258 35.0849C17.4258 35.4413 17.2842 35.7831 17.0322 36.0351C16.7802 36.2871 16.4384 36.4286 16.082 36.4286C15.7256 36.4286 15.3839 36.2871 15.1319 36.0351C14.8799 35.7831 14.7383 35.4413 14.7383 35.0849V27.0224C14.7383 26.666 14.8799 26.3242 15.1319 26.0722C15.3839 25.8202 15.7256 25.6786 16.082 25.6786C16.4384 25.6786 16.7802 25.8202 17.0322 26.0722C17.2842 26.3242 17.4258 26.666 17.4258 27.0224V35.0849ZM24.5924 35.0849C24.5924 35.4413 24.4509 35.7831 24.1989 36.0351C23.9469 36.2871 23.6051 36.4286 23.2487 36.4286C22.8923 36.4286 22.5505 36.2871 22.2985 36.0351C22.0465 35.7831 21.9049 35.4413 21.9049 35.0849V27.0224C21.9049 26.666 22.0465 26.3242 22.2985 26.0722C22.5505 25.8202 22.8923 25.6786 23.2487 25.6786C23.6051 25.6786 23.9469 25.8202 24.1989 26.0722C24.4509 26.3242 24.5924 26.666 24.5924 27.0224V35.0849Z" fill="#292929" stroke="#292929" strokeWidth="0.895833"/>
            <path d="M11.3893 28.8133V15.8246H28.4102V28.8133C28.4102 29.1697 28.2686 29.5115 28.0166 29.7635C27.7646 30.0155 27.4228 30.1571 27.0664 30.1571H12.7331C12.3767 30.1571 12.0349 30.0155 11.7829 29.7635C11.5309 29.5115 11.3893 29.1697 11.3893 28.8133ZM22.5329 9.58635L22.8496 9.90307L22.5329 9.58635C22.2809 9.83835 22.1393 10.1801 22.1393 10.5365C22.1393 10.8929 22.2809 11.2347 22.5329 11.4867C22.7849 11.7387 23.1267 11.8803 23.4831 11.8803C23.8395 11.8803 24.1812 11.7387 24.4332 11.4867C24.6853 11.2347 24.8268 10.8929 24.8268 10.5365C24.8268 10.1801 24.6853 9.83835 24.4332 9.58635C24.1812 9.33434 23.8395 9.19277 23.4831 9.19277C23.1267 9.19277 22.7849 9.33435 22.5329 9.58635ZM19.8997 6.8654C24.8432 6.8654 27.8464 9.71066 28.3565 13.1362H11.4429C11.9523 9.71076 14.9562 6.8654 19.8997 6.8654ZM15.3662 11.4867C15.6182 11.7387 15.96 11.8803 16.3164 11.8803C16.6728 11.8803 17.0146 11.7387 17.2666 11.4867C17.5186 11.2347 17.6602 10.8929 17.6602 10.5365C17.6602 10.1801 17.5186 9.83835 17.2666 9.58635C17.0146 9.33435 16.6728 9.19277 16.3164 9.19277C15.96 9.19277 15.6182 9.33434 15.3662 9.58635C15.1142 9.83835 14.9727 10.1801 14.9727 10.5365C14.9727 10.8929 15.1142 11.2347 15.3662 11.4867Z" fill="#292929" stroke="#292929" strokeWidth="0.895833"/>
            <path d="M24.8066 5.52148L23.3142 7.76017L24.8066 5.52148ZM14.0566 5.52148L15.2508 7.38661L14.0566 5.52148Z" fill="#292929"/>
            <path d="M24.8066 5.52148L23.3142 7.76017M14.0566 5.52148L15.2508 7.38661" stroke="#292929" strokeWidth="1.79167" strokeLinecap="round"/>
        </svg>
    ),
    'iOS': (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M28.8514 20.4407C28.8362 17.8519 30.0091 15.9008 32.3778 14.4621C31.053 12.5641 29.0487 11.5203 26.4068 11.3191C23.9053 11.1217 21.1684 12.7767 20.1663 12.7767C19.1072 12.7767 16.6854 11.3874 14.7799 11.3874C10.8473 11.4481 6.66797 14.5228 6.66797 20.7785C6.66797 22.6271 7.00581 24.5365 7.68148 26.5028C8.58491 29.0916 11.8418 35.4346 15.2392 35.3321C17.0157 35.2904 18.2721 34.0719 20.5838 34.0719C22.8272 34.0719 23.9888 35.3321 25.9703 35.3321C29.398 35.2828 32.3436 29.5167 33.2015 26.9203C28.6046 24.7529 28.8514 20.5735 28.8514 20.4407ZM24.8618 8.86311C26.7864 6.57797 26.6118 4.4978 26.5548 3.75C24.8543 3.84869 22.888 4.90776 21.7682 6.20976C20.5345 7.60666 19.8095 9.33381 19.9651 11.2811C21.8023 11.4216 23.4801 10.4764 24.8618 8.86311Z' fill='#292929' />
        </svg>
    ),
    'Web': (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M8.02034 29.4198H16.0981L15.7496 31.7944H13.9823C13.3882 31.7944 12.885 32.2844 12.885 32.8911C12.885 33.4978 13.3882 34.0004 13.9817 34.0004H26.1886C26.7821 34.0004 27.2853 33.4972 27.2853 32.8911C27.2833 32.6008 27.1671 32.323 26.9619 32.1178C26.7566 31.9126 26.4788 31.7964 26.1886 31.7944H24.4213L24.0723 29.4198H32.15C34.3566 29.4198 35.466 28.3617 35.466 26.1038V11.316C35.466 9.05816 34.3566 8 32.15 8H8.02034C5.81372 8 5 9.05816 5 11.316V26.1038C5 28.3617 5.81372 29.4198 8.02034 29.4198ZM7.33655 23.7425C6.94951 23.7425 6.78159 23.5873 6.78159 23.1876V11.3545C6.78159 10.4378 7.18184 10.0772 8.05887 10.0772H32.1115C33.0017 10.0772 33.3887 10.4378 33.3887 11.3545V23.187C33.3887 23.5867 33.2208 23.742 32.8338 23.742L7.33655 23.7425ZM20.0852 28.1425C19.4399 28.1425 18.885 27.6002 18.885 26.9423C18.885 26.3103 19.4399 25.7553 20.0852 25.7553C20.7304 25.7553 21.2854 26.3103 21.2854 26.9423C21.2854 27.6002 20.7304 28.1425 20.0852 28.1425Z' fill='#292929'/>
        </svg>
    ),
    'Windows': (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.6776 7.19458C32.7782 7.27579 32.8594 7.37849 32.9152 7.49515C32.971 7.61181 33 7.73948 33 7.86879V19.1346H19.1333V9.76837L31.9513 7.01953C32.0779 6.99219 32.209 6.99357 32.335 7.02357C32.461 7.05356 32.5786 7.1114 32.6793 7.19284M17.4 10.1393L7.68467 12.2208C7.4908 12.2625 7.31708 12.3693 7.19248 12.5236C7.06788 12.6778 6.99994 12.8701 7 13.0684V19.1346H17.4V10.1393ZM7 20.8678V28.6671C7.00003 28.8759 7.07545 29.0777 7.21239 29.2353C7.34933 29.393 7.53858 29.4958 7.74533 29.5251L17.4 30.9047V20.8678H7ZM19.1333 31.1525L32.012 32.9915C32.1348 33.0088 32.26 32.9996 32.3789 32.9645C32.4979 32.9293 32.6079 32.869 32.7016 32.7877C32.7953 32.7064 32.8704 32.6059 32.9219 32.493C32.9733 32.3802 33 32.2576 33 32.1335V20.8678H19.1333V31.1525Z" fill="#292929"/>
        </svg>
    ),
    'Hololens': (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6831 12.6836C19.6831 12.6836 34.9834 12.6836 34.9834 17.2737C34.9834 17.2737 35.1211 22.4146 34.6009 22.2463C33.4534 17.2737 19.6831 17.2737 19.6831 17.2737C19.6831 17.2737 5.91275 17.2737 4.76522 22.2463C4.24501 22.4146 4.38271 17.2737 4.38271 17.2737C4.38271 12.6836 19.6831 12.6836 19.6831 12.6836ZM19.6831 18.8037C31.9234 18.8037 33.0709 22.2463 33.0709 22.2463C31.5409 26.8364 30.3933 27.984 24.2732 27.984C19.6831 27.984 21.2131 25.6889 19.6831 25.6889C18.153 25.6889 19.6831 27.984 15.093 27.984C8.97282 27.984 7.82529 26.8364 6.29526 22.2463C6.29526 22.2463 7.44278 18.8037 19.6831 18.8037Z" fill="#292929"/>
        </svg>
    ),
    'Meta(Oculus)': (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0004 17.261C22.6494 13.086 24.9734 10.999 26.9724 11C29.9724 11 31.8669 14.3195 32.9724 18.8255C34.0284 23.129 33.7224 29 29.9724 29C28.3014 29 26.0004 26.6525 23.7504 23.522C22.3185 21.5495 21.0637 19.4543 20.0004 17.261ZM20.0004 17.261C17.3514 13.086 15.0274 10.999 13.0284 11C10.0284 11 8.13392 14.3195 7.02842 18.8255C5.97242 23.129 6.27842 29 10.0284 29C11.6994 29 14.0004 26.6525 16.2504 23.522C17.7504 21.435 19.0004 19.348 20.0004 17.261Z" stroke="#292929" strokeWidth="3" strokeLinecap="round" strokelinejoin="round"/>
        </svg>
    ),
  };
  
  const PLATFORM_CLASSES = {
    'Android': 'Android',
    'iOS': 'iOS',
    'Web': 'Web',
    'Windows': 'Windows',
    'Hololens': 'Hololens',
    'Meta(Oculus)': 'Meta(Oculus)'
  };
  
  const PlatformIcon = ({ platform }) => {
    const className = PLATFORM_CLASSES[platform];
    return (
      <div className={className}>
        {PLATFORM_ICONS[platform]}
      </div>
    );
  };
  
  export default PlatformIcon;