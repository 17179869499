import { useLenis } from 'lenis/react';
import React, { useState, useEffect } from 'react';

function ScrollToTop() {
  const [visible, setVisible] = useState(false);
  const lenis = useLenis();

  const scrollToTop = () => {
    lenis.scrollTo(0, {
      duration: 1.5,
      immediate: false
    });
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <div className='scroll-top-btn' onClick={scrollToTop}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='42'
          viewBox='0 0 32 42'
          fill='none'>
          <path
            d='M2.5 16.1631L16 2.66309L29.5 16.1631'
            stroke='white'
            strokeWidth='3.825'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <line
            x1='15.9996'
            y1='39.3375'
            x2='15.9996'
            y2='4.9125'
            stroke='white'
            strokeWidth='3.825'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );
}

export default ScrollToTop;
