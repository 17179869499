import React, { useState, useEffect } from 'react';
import { motion, useScroll } from 'framer-motion';
import { HeaderVariants } from '../animations';
import { useLenis } from 'lenis/react';

const NAV_ITEMS = [
  { id: 0, navTitle: 'About', href: '#about' },
  { id: 1, navTitle: 'Services', href: '#services' },
  { id: 2, navTitle: 'Contents', href: '#contents' },
  { id: 3, navTitle: 'Portfolio', href: '#portfolio' },
  { id: 4, navTitle: 'Contact', href: '#contact' },
];

const DesktopHeader = ({ isScrolled }) => {
  const lenis = useLenis();

  return (
    <motion.header
      animate={{
        backgroundColor: isScrolled
          ? 'rgba(0, 0, 0, 0.85)'
          : 'rgba(17, 17, 17, 0)',
        boxShadow: isScrolled ? '0 2px 15px rgba(0, 0, 0, 0.3)' : 'none',
      }}
      transition={{ duration: 0.3 }}>
      <div className='header-inner'>
        <h1 className='logo'>
          <a href='/'>DNSOFT</a>
        </h1>
        <ul>
          {NAV_ITEMS.slice(0, 4).map(item => (
            <li key={item.id}>
              <a
                href={item.href}
                onClick={e => {
                  e.preventDefault();
                  lenis.scrollTo(item.href);
                }}>
                {item.navTitle}
              </a>
            </li>
          ))}
        </ul>
        <a 
          href='/#contact' 
          className='contact-btn'
          onClick={e => {
            e.preventDefault();
            lenis.scrollTo('#contact');
          }}>
          Contact
        </a>
      </div>
    </motion.header>
  );
};

const MobileHeader = ({ mobileNavOpen, setMobileNavOpen }) => {
  const lenis = useLenis();

  const handleMobileNavOpen = isOpen => {
    setMobileNavOpen(isOpen);

    if (isOpen) {
      lenis?.stop();
    } else {
      lenis?.start();
    }
  };

  return (
    <motion.nav
      className='mobile-nav'
      initial='closed'
      animate={mobileNavOpen ? 'opened' : 'closed'}>
      <div className='logo-container'>
        <motion.h1 variants={HeaderVariants.hideNavItems} className='logo'>
          <a href='/'>DNSOFT</a>
        </motion.h1>
      </div>
      <div className='menu-container'>
        <motion.div
          variants={HeaderVariants.hideNavItems}
          onClick={() => handleMobileNavOpen(true)}
          className='hamburger-menu'>
          <span></span>
          <span></span>
          <span></span>
        </motion.div>
      </div>

      <motion.div variants={HeaderVariants.mobileMenu} className='mobile-menu'>
        <motion.button
          className='close-button'
          variants={HeaderVariants.fadeIn}
          onClick={() => handleMobileNavOpen(false)}>
          <span className='close-icon'></span>
        </motion.button>
        <div className='inner'>
          <motion.ul variants={HeaderVariants.ul}>
            {NAV_ITEMS.map(item => (
              <motion.li whileTap={{ scale: 0.95 }} key={item.id}>
                <motion.div variants={HeaderVariants.li}>
                  <a
                    href={item.href}
                    onClick={() => handleMobileNavOpen(false)}
                    style={{ color: 'inherit', textDecoration: 'none' }}>
                    {item.navTitle}
                  </a>
                </motion.div>
              </motion.li>
            ))}
          </motion.ul>
          <motion.div variants={HeaderVariants.fadeIn} className='contact'>
            <h5>+02-837-8313</h5>
            <h5>ask@dnsoft.co.kr</h5>
          </motion.div>
        </div>
      </motion.div>
    </motion.nav>
  );
};

const Header = () => {
  const { scrollY } = useScroll();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(scrollY.get() > 20);
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollY]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 820);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileHeader
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        />
      ) : (
        <DesktopHeader isScrolled={isScrolled} />
      )}
    </>
  );
};

export default Header;
