import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import videoBackground1 from '../assets/images/splash-bg.mov';
import { useLenis } from 'lenis/react';

const SplashScreen = () => {
  const text = window.innerWidth <= 768 
  ? 'AI와 실감형 콘텐츠로\n교육의 미래를 만듭니다.'
  : 'AI와 실감형 콘텐츠로 교육의 미래를 만듭니다.';
  const lenis = useLenis();
  
  useEffect(() => {
    lenis?.stop();
    
    return () => {
      lenis?.start();
    };
  }, [lenis]);

  return (
    <section className='splash-section'>
      <div className='bg'>
        <video src={videoBackground1} autoPlay loop muted />
        <div className='text'>
          <motion.h2
            initial='hidden'
            animate='visible'
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.04,
                },
              },
            }}>
            {text.split('')
              .map((char, index) => (
                <motion.span
                  key={index}
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                  }}
                  transition={{ duration: 0.8 }}
                  >
                  {char}
                </motion.span>
              ))}
          </motion.h2>
        </div>
      </div>
    </section>
  );
};

export default SplashScreen;