import React from 'react';
import { motion } from 'framer-motion';
import {
  titleLeftVariants,
  textLeftVariants,
  textVariants,
} from '../animations';

const About = () => {
  const boxVariants = {
    hidden: { height: 0 },
    visible: height => ({
      height:
        window.innerWidth <= 1440
          ? mobile_boxHeights[boxHeights.indexOf(height)]
          : height,
      transition: {
        duration: 1.2,
        ease: 'easeInOut',
        delay: 0.4,
      },
    }),
  };

  const boxHeights = [160, 380, 600];
  const mobile_boxHeights = [105, 180, 260];

  const aboutData = [
    {
      title: '11년',
      subtitle: '개발 업력',
      description: (
        <>
          2013년부터 최신 기술을 활용한
          <br />
          지능형 교육콘텐츠를
          <br />
          개발해오고 있습니다.
        </>
      ),
    },
    {
      title: '110개',
      subtitle: '프로젝트 완수',
      description: (
        <>
          50개 이상의 대한민국 대표 기업에
          <br />
          110개 이상의 콘텐츠 개발 프로젝트를
          <br />
          완수하였습니다.
        </>
      ),
    },
    {
      title: '6만명',
      subtitle: '서비스 사용자',
      description: (
        <>
          4만명의 '알공 잉글리시플래닛' 회원과
          <br />
          2만명의 '마법천자문 공식앱' 회원에게
          <br />
          서비스하고 있습니다.
        </>
      ),
    },
  ];

  return (
    <section id='about' className='about-section'>
      <div className='content inner'>
        <motion.div
          className='title'
          variants={titleLeftVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.4 }}>
          <h3>
            마음을 움직이는 <span className='border-text'>Creative</span>
          </h3>
          <h3>
            생각과 지능을 입히는 <span className='border-text'>Technology</span>
          </h3>
        </motion.div>

        <motion.div
          className='text'
          variants={textLeftVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.4 }}>
          <p className='pc'>
            우리는 창의적인 스토리텔링과 시각적 요소로 감동을 전하는 교육
            콘텐츠를 제작합니다.
            <br />
            AI 기술을 통해 학습 경험을 지능적이고 직관적으로 확장하여
            <br />
            학습자가 깊이 몰두하고 교육자와 상호작용하며 성장할 수 있는 환경을
            제공합니다.
            <br />
            크리에이티브와 기술을 조화롭게 결합해 혁신적인 교육 환경을 창출하고,
            <br />
            학습자의 잠재력을 최대한 발휘할 수 있도록 지원합니다.
          </p>
          <p className='mo'>
            창의적인 스토리텔링과 AI기술로 몰입형 교육 콘텐츠를 제작합니다.
            학습자와 교육자가 상호작용하며 성장할 수 있는 환경을 조성해,
            학습자의 잠재력을 최대한 발휘하도록 지원합니다.
          </p>
        </motion.div>

        <motion.div 
          className='about'
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.4 }}>
          {boxHeights.map((height, index) => (
            <div className='step-box' key={index}>
              <motion.div
                variants={textVariants}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true, amount: 0.4 }}>
                <motion.h4
                  initial={{ opacity: 0, y: -30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1.4 }}
                  viewport={{ once: true }}>
                  {aboutData[index].title}
                </motion.h4>
                <motion.div
                  className='text-box'
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1.4 }}
                  viewport={{ once: true }}>
                  <p className='bold'>{aboutData[index].subtitle}</p>
                  <p>{aboutData[index].description}</p>
                </motion.div>
              </motion.div>
              <motion.div
                className='box'
                variants={boxVariants}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true, amount: 0.4 }}
                custom={height}
              />
            </div>
          ))}
        </motion.div>

        <div className='mo'>
          {[...aboutData].reverse().map((item, index) => (
            <motion.div
              key={`mobile-${index}`}
              className='mobile-text-item'
              variants={textVariants}
              initial='hidden'
              whileInView='visible'
              custom={index}
              viewport={{ once: true, amount: 0.5 }}>
              <h4>
                {item.subtitle} {item.title}
              </h4>
              <p>{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
      <div className='bg-effect01'></div>
      <div className='bg-effect02'></div>
    </section>
  );
};

export default About;
