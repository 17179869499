import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { portfolioData } from '../portfoiloData';
import PlatformIcon from '../component/PlatformIcon';
import { motion } from 'framer-motion';

const Portfolio = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const currentPortfolio =
    id && portfolioData
      ? portfolioData.find(item => item.id === parseInt(id))
      : portfolioData?.[0];

  if (!currentPortfolio) {
    return <div>포트폴리오를 찾을 수 없습니다.</div>;
  }

  const currentIndex = portfolioData.findIndex(
    item => item.id === parseInt(id)
  );

  const handleNavigation = direction => {
    let nextIndex;
    if (direction === 'prev') {
      nextIndex =
        currentIndex > 0 ? currentIndex - 1 : portfolioData.length - 1;
    } else {
      nextIndex =
        currentIndex < portfolioData.length - 1 ? currentIndex + 1 : 0;
    }
    navigate(`/portfolio/${portfolioData[nextIndex].id}`);
  };

  let title = currentPortfolio.title + ' ' + currentPortfolio.subtitle;

  const handleClose = () => {
    sessionStorage.setItem('skipSplash', 'true');

    navigate('/#portfolio', { 
      state: { scrollToPortfolio: true },
      replace: true,
    });
  };

  return (
    <motion.section key={id} className='portfolio-section'>
      <div className='header'>
        <motion.img
          className='header-bg'
          src={require(`../assets/images/${currentPortfolio.images.main}.jpg`)}
          alt={currentPortfolio.title}
          initial={{ scale: 1.2 }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.8,
              ease: 'easeOut',
            },
          }}
        />
      
        <div className='close-btn' onClick={handleClose}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='33'
            viewBox='0 0 32 33'
            fill='none'>
            <path
              d='M25.1204 7.38064L25.1204 7.38069C25.4172 7.67761 25.584 8.08026 25.584 8.50011C25.584 8.91995 25.4172 9.32261 25.1204 9.61952L25.1204 9.61955L18.2398 16.5001L25.1204 23.3807L25.1204 23.3806L25.1234 23.3838C25.4118 23.6824 25.5714 24.0823 25.5678 24.4975C25.5642 24.9126 25.3977 25.3097 25.1041 25.6033C24.8106 25.8969 24.4135 26.0634 23.9983 26.067C23.5832 26.0706 23.1832 25.911 22.8846 25.6226L22.8846 25.6226L22.8815 25.6196L16.0009 18.739L9.12038 25.6196L9.12041 25.6196L9.11728 25.6226C8.81866 25.911 8.41871 26.0706 8.00356 26.067C7.58842 26.0634 7.1913 25.8969 6.89773 25.6033C6.60417 25.3097 6.43765 24.9126 6.43405 24.4975C6.43044 24.0823 6.59003 23.6824 6.87845 23.3838L6.87842 23.3837L6.88149 23.3807L13.762 16.5001L6.88298 9.62104C6.73251 9.4753 6.61245 9.30114 6.52977 9.10865C6.44678 8.91548 6.4031 8.70772 6.40128 8.49748C6.39945 8.28725 6.43951 8.07876 6.51912 7.88417C6.59873 7.68958 6.7163 7.5128 6.86497 7.36414C7.01363 7.21547 7.19041 7.09791 7.385 7.0183L7.47952 7.24934L7.385 7.0183C7.57958 6.93868 7.78807 6.89862 7.99831 6.90045C8.20854 6.90228 8.41631 6.94596 8.60948 7.02894C8.80196 7.11162 8.97612 7.23168 9.12186 7.38215L16.0009 14.2612L22.8815 7.38067L22.8815 7.38064C23.1784 7.08381 23.5811 6.91706 24.0009 6.91706C24.4208 6.91706 24.8234 7.08381 25.1204 7.38064Z'
              fill='white'
              stroke='white'
              strokeWidth='0.5'
            />
          </svg>
        </div>
        <div className='prev-btn' onClick={() => handleNavigation('prev')}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='34'
            height='34'
            viewBox='0 0 34 34'
            fill='none'>
            <path
              d='M17 1.7002L1.7 17.0002L17 32.3002'
              stroke='white'
              strokeWidth='3.4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <line
              x1='1.7'
              y1='-1.7'
              x2='32.3'
              y2='-1.7'
              transform='matrix(-1 8.74228e-08 8.74228e-08 1 34 18.7002)'
              stroke='white'
              strokeWidth='3.4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <div className='next-btn' onClick={() => handleNavigation('next')}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='34'
            height='34'
            viewBox='0 0 34 34'
            fill='none'>
            <path
              d='M17 1.7002L32.3 17.0002L17 32.3002'
              stroke='white'
              strokeWidth='3.4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <line
              x1='1.7'
              y1='17.0002'
              x2='32.3'
              y2='17.0002'
              stroke='white'
              strokeWidth='3.4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>

      <div className='inner'>
        <div className='text-wrap'>
          <div className='title-wrap'>
            <motion.h2
              initial='hidden'
              animate='visible'
              variants={{
                visible: {
                  transition: {
                    staggerChildren: 0.04,
                  },
                },
              }}>
              {title.split('').map((char, index) => (
                <motion.span
                  key={index}
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                  }}
                  transition={{ duration: 0.8 }}>
                  {char}
                </motion.span>
              ))}
            </motion.h2>

            <motion.p
              className='year'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8, duration: 1.2 }}>
              {currentPortfolio.year}
            </motion.p>
          </div>
          <motion.h4
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8, duration: 1.2 }}>
            {currentPortfolio.tags.join(' ')}
          </motion.h4>
          <motion.p
            className='text'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 1.2 }}>
            {currentPortfolio.description}
          </motion.p>

          <motion.div
            className='icon-box'
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.6 }}
            viewport={{ once: true, amount: 0.4 }}>
            {currentPortfolio.platform.map(platform => (
              <PlatformIcon key={platform} platform={platform} />
            ))}
          </motion.div>
        </div>

        <div className='flex-wrap'>
          <div>
            <motion.h5
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.6 }}
              viewport={{ once: true, amount: 0.3 }}>
              주요기능
            </motion.h5>
          </div>
          <motion.div
            className='grid'
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.6 }}
            viewport={{ once: true, amount: 0.3 }}>
            {currentPortfolio.features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.2 + index * 0.1,
                  duration: 0.6,
                }}
                viewport={{ once: true, amount: 0.3 }}>
                <h6>{feature.title}</h6>
                <p>{feature.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
        <div className='flex-wrap'>
          <div>
            <motion.h5
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.6 }}
              viewport={{ once: true, amount: 0.5 }}>
              성과 및 결과
            </motion.h5>
          </div>
          <motion.div
            className='grid'
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.6 }}
            viewport={{ once: true, amount: 0.5 }}>
            {currentPortfolio.results.map((result, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.2 + index * 0.1,
                  duration: 0.6,
                }}
                viewport={{ once: true, amount: 0.5 }}>
                <h6>{result.title}</h6>
                <p>{result.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>

      <div className='inner inner-mo'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.6 }}
          viewport={{ once: true, amount: 0.4 }}
          className='img-wrap'>
          <div className='img'>
            <img
              src={require(
                `../assets/images/${currentPortfolio.images.sub[0]}.jpg`
              )}
              alt={currentPortfolio.title}
            />
          </div>
          <div className='img'>
            <img
              src={require(
                `../assets/images/${currentPortfolio.images.sub[1]}.jpg`
              )}
              alt={currentPortfolio.title}
            />
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Portfolio;
